
import { Component, OnInit, Input, EventEmitter, Output, Injectable } from '@angular/core';
import { IAnnonce } from 'src/app/interfaces/annonce';

import * as R from 'ramda';
import moment from 'moment';
import { AnnonceService } from 'src/app/providers/annonce.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { ContactListService } from 'src/app/providers/contact-list.service';
import { IContactList } from 'src/app/interfaces/contact-list';
import { AgenceService } from 'src/app/providers/agence.service';
import { Agence } from 'src/app/interfaces/agence';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-annonce-new',
  templateUrl: './annonce-new.component.html',
  styleUrls: ['./annonce-new.component.scss']
})
export class AnnonceNewComponent implements OnInit {

  @Input() client = null;
  @Output() annonce: EventEmitter<any> = new EventEmitter<any>();

  newAnnonce: IAnnonce;
  origAnnonce: IAnnonce;

  agences = [new Agence(0, '', '...', '', '', '')];
  selectedAgence = this.agences[0];

  constructor(
    private annonceService: AnnonceService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private agenceService: AgenceService,
    private httpClient: HttpClient,
  ) { }

  ngOnInit() {
    console.log('ade edit');
    this.newAnnonce = { id: 0, agenceid: 0, code_agence: '', url: '', reference: '', smstext: '', contact: '' , nbrequetes: 0 };

    this.agenceService.GetAll(this.client.id).subscribe( result => {
      result.forEach(a => this.agences.push(a));
      if (result.length === 1) {
        // if only 1 agence remove default and keep this agence as single choice
        this.agences.shift();
      }
    });
  }

  selectAgence(agence) {
    this.selectedAgence = agence;
    if (this.newAnnonce.smstext === '') {
      this.newAnnonce.smstext = this.selectedAgence.smstext;
      this.newAnnonce.agenceid = this.selectedAgence.id;
    }
  }

  loadPreviewAnnonce() {
    window.open(
      this.newAnnonce.url,
      '',
      'toolbar=no, menubar=no,scrollbars=yes,resizable=yes,location=no,directories=no,status=no, width=1000, height=700');
  }

  cancel() {
    this.annonce.emit(this.origAnnonce);
  }

  create() {
    this.spinner.show();
    this.annonceService.Add(this.client.id, this.newAnnonce).subscribe( (data: any) => {
      if (data.success) {
        this.toastr.success('L\'annonce a été créée', '');

        console.log('new annonce id', data.id);
        this.newAnnonce.id = data.id;

      } else {
        this.toastr.error(data.message, 'Erreur', { timeOut: 0 });
      }
      this.spinner.hide();
    });
  }
}
