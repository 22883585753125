import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { APP_BASE_HREF } from '@angular/common';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { CommonModule, DecimalPipe, LocationStrategy, HashLocationStrategy } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';

import { UserLoginComponent } from './components/login/user-login.component';
import { AdminLoginComponent } from './components/login/admin-login.component';
import { NgbdSortableHeader } from './directives/sortable.directive';
import { NgxPaginationModule } from 'ngx-pagination';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxPrintModule } from 'ngx-print';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxDropzoneModule } from 'ngx-dropzone';
// import { NgxStripeModule } from '@nomadreservations/ngx-stripe';
import { PapaParseModule } from 'ngx-papaparse';

import { BasicAuthInterceptor } from './guards/basic-auth.interceptor';
import { ErrorInterceptor } from './guards/error.interceptor';

import { UserService } from './providers/user.service';
import { BlacklistService } from './providers/blacklist.service';
import { CampaignService } from './providers/campaign.service';
import { ContactListService } from './providers/contact-list.service';
import { ExportDataService } from './providers/export-data.service';

import { LogoutComponent } from './components/logout/logout.component';
import { MenuHeaderComponent } from './components/menu-header/menu-header.component';
import { AdminMenuComponent } from './components/admin-menu/admin-menu.component';
import { HomePageComponent } from './components/home-page/home-page.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ClientComponent } from './components/client/client.component';
import { ClientEditorComponent } from './components/client-editor/client-editor.component';
import { ModalComponent } from './modals/modal.component';
import { DialogComponent } from './dialog/dialog.component';
import { ClientSelectComponent } from './components/client-select/client-select.component';
import { MustMatchDirective } from './directives/must-match.directive';
import { AppPatternDirective } from './directives/app-pattern.directive';
import { ModelesComponent } from './components/modeles/modeles.component';
import { FactureComponent } from './components/facture/facture.component';
import { CreditHistoryComponent } from './components/credit-history/credit-history.component';
import { PasswordRequestComponent } from './components/password-request/password-request.component';
import { NousContacterComponent } from './components/nous-contacter/nous-contacter.component';
import { AchatCreditComponent } from './components/achat-credit/achat-credit.component';
import { BlacklistComponent } from './components/blacklist/blacklist.component';
import { CampaignListComponent } from './components/campaign-list/campaign-list.component';
import { CampaignComponent } from './components/campaign/campaign.component';
import { ContactListComponent } from './components/contact-list/contact-list.component';
import { FocusDirective } from './directives/app-focus.directive';
import { ContactComponent } from './components/contact/contact.component';
import { ContactListUploadComponent } from './components/contact-list-upload/contact-list-upload.component';
import { ImportFileComponent } from './components/import-file/import-file.component';
import { CreditAdminComponent } from './components/credit-admin/credit-admin.component';
import { ConsoPostpaidComponent } from './components/conso/conso-postpaid.component';
import { ConsoForfaitComponent } from './components/conso/conso-forfait.component';
import { ConsoRecapComponent } from './components/conso/conso-recap.component';
import { ConsoPerAdminComponent } from './components/conso/conso-per-admin.component';
import { AdminManagerComponent } from './components/admin-manager/admin-manager.component';
import { MlpMenuComponent } from './components/mlp/mlp-menu.component';
import { MlpListComponent } from './components/mlp/mlp-list.component';
import { MlpCampagneComponent } from './components/mlp/mlp-campagne.component';
import { MlpClientComponent } from './components/mlp/mlp-client.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AdeMenuComponent } from './components/ade/ade-menu.component';
import { AdeListComponent } from './components/ade/ade-list.component';
import { AdeDetailsComponent } from './components/ade/ade-details.component';
import { AdeEditComponent } from './components/ade/ade-edit.component';
import { AdeNewComponent } from './components/ade/ade-new.component';

import { AgenceComponent } from './components/agence/agence.component';

import { AnnonceMenuComponent } from './components/annonce/annonce-menu.component';
import { AnnonceStatComponent } from './components/annonce/annonce-stat.component';
import { AnnonceListComponent } from './components/annonce/annonce-list.component';
import { AnnonceNewComponent } from './components/annonce/annonce-new.component';
import { AnnonceEditComponent } from './components/annonce/annonce-edit.component';

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    DashboardComponent,
    ClientComponent,
    ClientEditorComponent,
    AdminMenuComponent,
    NgbdSortableHeader,
    MenuHeaderComponent,
    UserLoginComponent,
    AdminLoginComponent,
    LogoutComponent,
    ModalComponent,
    DialogComponent,
    ClientSelectComponent,
    MustMatchDirective,
    AppPatternDirective,
    ModelesComponent,
    FactureComponent,
    CreditHistoryComponent,
    PasswordRequestComponent,
    NousContacterComponent,
    AchatCreditComponent,
    BlacklistComponent,
    CampaignListComponent,
    CampaignComponent,
    ContactListComponent,
    FocusDirective,
    ContactComponent,
    ContactListUploadComponent,
    ImportFileComponent,
    CreditAdminComponent,
    ConsoPostpaidComponent,
    ConsoForfaitComponent,
    ConsoRecapComponent,
    ConsoPerAdminComponent,
    AdminManagerComponent,
    MlpMenuComponent,
    MlpListComponent,
    MlpCampagneComponent,
    MlpClientComponent,
    AdeMenuComponent,
    AdeListComponent,
    AdeDetailsComponent,
    AdeEditComponent,
    AdeNewComponent,
    AgenceComponent,
    AnnonceMenuComponent,
    AnnonceStatComponent,
    AnnonceListComponent,
    AnnonceNewComponent,
    AnnonceEditComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgbModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgxPaginationModule,
    ClipboardModule,
    NgxPrintModule,
    NgxSpinnerModule,
    NgxDropzoneModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      newestOnTop: false,
      closeButton: true,
    }),
    // NgxStripeModule.forRoot(environment.stripePublicKey),
    PapaParseModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  exports: [
    FocusDirective,
    AppPatternDirective,
  ],
  providers: [
    UserService,
    BlacklistService,
    CampaignService,
    ContactListService,
    ExportDataService,
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    DecimalPipe,
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    // {provide: APP_BASE_HREF, useValue: '/app'},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

