import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { IContactList } from '../interfaces/contact-list';

@Injectable({
  providedIn: 'root'
})
export class ContactListService {
  selectedCList: IContactList;

  constructor(
    private httpClient: HttpClient,
  ) { }

  AdminGetAll(adminid): Observable<any> {
    const url = environment.apiUrl + '/lists/admin' + adminid;
    return this.httpClient.get(url);
  }

  GetAll(clientid): Observable<any> {
    const url = environment.apiUrl + '/lists/user/' + clientid;
    return this.httpClient.get(url);
  }

  GetList(clientid, listid): Observable<any> {
    const url = environment.apiUrl + '/lists/get/' + clientid + '/' + listid;
    return this.httpClient.get(url);
  }

  Add(clientid, list): Observable<any> {
    const url = environment.apiUrl + '/lists/' + clientid;
    return this.httpClient.post(url, list);
  }

  Update(clientid, list): Observable<any> {
    const url = environment.apiUrl + '/lists/' + clientid + '/' + list.id;
    return this.httpClient.put(url, list);
  }

  SetUrl(clientid, list): Observable<any> {
    console.log('setUrl');
    const url = environment.apiUrl + '/lists/' + clientid + '/' + list.id + '/longurl';
    return this.httpClient.put(url, list);
  }

  Delete(clientid, listid): Observable<any> {
    const url = environment.apiUrl + '/lists/' + clientid + '/' + listid;
    return this.httpClient.delete(url);
  }

  /****** CONTACTS */
  ContactGetAll(clientid, listid): Observable<any> {
    const url = environment.apiUrl + '/contacts/' + clientid + '/' + listid;
    return this.httpClient.get(url);
  }

  ContactAdd(clientid, listid, data): Observable<any> {
    const url = environment.apiUrl + '/contacts/' + clientid + '/' + listid;
    return this.httpClient.post(url, data);
  }

  ContactSelect(clientid, listid, contactid, selected): Observable<any> {
    const url = environment.apiUrl + '/contacts/select/' + clientid + '/' + listid;
    const data = { contact: contactid, selected };
    return this.httpClient.post(url, data);
  }

  AddContactToList(clientid, listid, vars): Observable<any> {
    const url = environment.apiUrl + '/contacts/addnew/' + clientid + '/' + listid;
    const data = { json_data: vars };
    return this.httpClient.post(url, data);
  }

  UpdateListAndContacts(clientid, list): Observable<any> {
    const url = environment.apiUrl + '/contacts/update/' + clientid + '/' + list.id;
    const data = { actif: list.actif, contacts: list.contacts };
    return this.httpClient.post(url, data);
  }
}
