import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/providers/user.service';
import { IUser } from 'src/app/interfaces/user';
import { MlpService } from 'src/app/providers/mlp.service';

import { AuthenticationService } from 'src/app/providers/authentication.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv';
import { IModele } from 'src/app/interfaces/modele';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-mlp-menu',
  templateUrl: './mlp-menu.component.html',
  styleUrls: ['./mlp-menu.component.scss']
})
export class MlpMenuComponent implements OnInit {

  client: IUser;
  activeTab = 0;
  selectedCampaigns = [];
  filterParams = {};

  chefsRegion = [];
  commerciaux = [];
  customers = [];

  diffuseur = null;

  imgUrl = '//isisms.fr';
  showPhotos = false;
  photos = [];
  photoIndex = 0;
  selectedNim = '';
  selectedCusto = { nim: '', photo1: '', photo2: '', photo3: '' };

  constructor(
    private userService: UserService,
    private mlpService: MlpService,
  ) { }

  ngOnInit() {
    this.userService.selectedUser$.subscribe( user =>  {
      this.client = user;
    });
    this.selectTab(0);  // display all MLP campaign
    this.loadMlpCustomer();
    this.loadMlpEmployee();
  }

  selectTab(tab) {
    if (this.activeTab !== tab) {
      this.activeTab = tab;
      return;
    }
    this.activeTab = -1;
    setTimeout(() => {
      this.activeTab = tab;
    }, 0);
  }

  selectCampaigns(selection) {
    console.log('selectCampaigns', selection);
    this.selectedCampaigns = selection.campaigns;
    this.filterParams = selection.filterParams;
    this.selectTab(1);   // display selected campaign
  }

  selectNim(nim) {
    this.diffuseur = this.customers.find( c => c.nim === nim);
    this.selectTab(2);
  }

  loadMlpEmployee() {
    this.mlpService.GetMlpEmployee().subscribe((data: any) => {
      if (data.success) {
        this.chefsRegion = [];
        this.commerciaux = [];
        data.employee.forEach(e => {
          if (e.is_commercial === '1') {
            this.commerciaux.push(e);
          } else {
            this.chefsRegion.push(e);
          }
        });
      }
    });
  }

  loadMlpCustomer() {
    this.mlpService.GetMlpCustomer().subscribe((data: any) => {
      if (data.success) {
        this.customers = data.customer;
      }
    });
  }

  showPhoto(c) {
    this.photoIndex = 0;
    this.selectedCusto = c;
    this._showPhoto();
  }


  _showPhoto() {
    if (!this.selectedCusto) {
      return;
    }
    console.log('showPhoto');
    // display photos + tools to rotate
    this.selectedNim = this.selectedCusto.nim;
    this.photos = [];
    if (this.selectedCusto.photo1 !== '') {
      this.photos.push('/mlp/' + this.selectedCusto.photo1 + '?' + Math.random() * 1000000);
    }
    if (this.selectedCusto.photo2 !== '') {
      this.photos.push('/mlp/' + this.selectedCusto.photo2 + '?' + Math.random() * 1000000);
    }
    if (this.selectedCusto.photo3 !== '') {
      this.photos.push('/mlp/' + this.selectedCusto.photo3 + '?' + Math.random() * 1000000);
    }

    if (this.photos.length === 0) {
      return;
    }
    this.showPhotos = true;

  }

  doRotate(rotate) {
    this.mlpService.ImageRotate({ filename: this.photos[this.photoIndex].split('?')[0], degrees: (rotate > 0 ? 90 : -90)}).subscribe( (res) => {
      this._showPhoto();
    });
  }

  openFull() {
    window.open(this.imgUrl + this.photos[this.photoIndex], '', 'toolbar=no, menubar=no,scrollbars=yes,resizable=yes,location=no,directories=no,status=no, width=1200, height=800');
  }


}
