import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {

  isAdmin = false;
  adminId = 0;
  adminRole = 0;
  userId = 0;
  currentUser = sessionStorage.getItem('currentUser');
  currentAdmin = sessionStorage.getItem('currentAdmin');

  constructor(
    private http: HttpClient,
    private router: Router,
  ) {
    if (this.currentUser) {
      const user = JSON.parse(this.currentUser);
      this.resetAdminInfo();
      this.userId = user.userId;
    } else if (this.currentAdmin) {
      const admin = JSON.parse(this.currentAdmin);
      this.setAdminInfo(admin);
      this.userId = 0;
    }
  }

  resetAdminInfo() {
    this.isAdmin = false;
    this.adminId = 0;
    this.adminRole = 0;
  }

  setAdminInfo(admin) {
    this.isAdmin = true;
    this.adminId = admin.admin;
    this.adminRole = admin.role;
  }

  logout() {
    // remove user from local storage to log user out
    if (sessionStorage.getItem('currentUser')) {
      sessionStorage.removeItem('currentUser');
      this.router.navigate(['/login']);
    } else {
      this.router.navigate(['/admin']);
      sessionStorage.removeItem('currentAdmin');
    }
    this.resetAdminInfo();
    this.userId = 0;
  }

  loginUser(username: string, password: string) {
    console.log('loginUser');
    sessionStorage.removeItem('currentAdmin');
    return this.http.post<any>(`${environment.apiUrl}/authenticate`, { username, password })
      .pipe(
        map(response => {
          console.log('authService login user', response);
          if (response.success) {
            // store user details and basic auth credentials in local storage
            // to keep user logged in between page refreshes
            const user = {
              authdata: window.btoa(username + ':' + password),
              admin: 0,
              role: 0,
              userId: response.clientid,
            };
            this.resetAdminInfo();
            sessionStorage.setItem('currentUser', JSON.stringify(user));
            return user;
          } else {
            sessionStorage.removeItem('currentUser');
            throw new Error(response.message);
          }
        })
      );
  }

  loginAdmin(username: string, password: string) {
    console.log('loginAdmin');
    sessionStorage.removeItem('currentUser');
    return this.http.post<any>(`${environment.apiUrl}/admins`, { username, password })
      .pipe(
        map(response => {
          console.log('authService login admin', response);
          if (response.success) {
            // store user details and basic auth credentials in local storage
            // to keep user logged in between page refreshes
            const admin = {
              authdata: window.btoa(username + ':' + password),
              admin: response.adminid,
              role: response.role,
              userId: 0,
            };
            sessionStorage.setItem('currentAdmin', JSON.stringify(admin));
            this.setAdminInfo(admin);
            return admin;
          } else {
            sessionStorage.removeItem('currentAdmin');
            throw new Error(response.message);
          }
        })
      );
  }

  SetCredentials(username, password, clientid) {
    if (!this.isAdmin) {
      const user = {
        authdata: window.btoa(username + ':' + password),
        admin: 0,
        role: 0,
        userId: clientid
      };
      sessionStorage.setItem('currentUser', JSON.stringify(user));
    }
  }

  requestCodeMail(username, isAdmin) {
    const data = { username, isAdmin };
    return this.http.post(`${environment.apiUrl}/clients/requestcodemail`, data);
  }

  checkCode(username, code, isAdmin) {
    const data = { username, code, isAdmin };
    return this.http.post(`${environment.apiUrl}/clients/checkcode`, data);
  }

  changePassword(username, code, password1, password2, isAdmin) {
    const data = { username, code, password1, password2, isAdmin };
    return this.http.post(`${environment.apiUrl}/clients/changepassword`, data);
  }

}
