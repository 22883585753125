import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService } from 'src/app/providers/user.service';
import { IUser } from 'src/app/interfaces/user';
import { AuthenticationService } from 'src/app/providers/authentication.service';
import { ToastrService } from 'ngx-toastr';
import * as R from 'ramda';
import { Router } from '@angular/router';
import { EventManagerService } from 'src/app/providers/event-manager.service';
import { ClipboardService } from 'ngx-clipboard';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-client-editor',
  templateUrl: './client-editor.component.html',
  styleUrls: ['./client-editor.component.scss'],
})
export class ClientEditorComponent implements OnInit, OnDestroy {

  client: IUser = null;
  clientSubscribe = null;
  clientOrig: IUser = null;
  admins = [];
  activeTab = 'coord';
  isAdmin = this.authService.isAdmin;
  dataLoading = false;
  password = { password1: '', password2: '' };

  showGenkeyConfirm = false;
  showSaveKey = false;
  showForfaitChange = false;
  newForfait = { period: 0, quantity: 0};

  entities = [];

  constructor(
    private authService: AuthenticationService,
    private userService: UserService,
    private toastr: ToastrService,
    private router: Router,
    private eventManager: EventManagerService,
    private clipboardService: ClipboardService,
    private spinner: NgxSpinnerService,
  ) {
    console.log('edition init');


    this.clientSubscribe = this.userService.selectedUser$.subscribe(user => {
      this.client = R.clone(user);
      this.clientOrig = R.clone(user);
      console.log('client selected', this.client);

      if (this.client.id === 0) {
        this.client.admin = this.authService.adminId;
      }

    });

    this.userService.GetAdmins(this.authService.adminId, this.authService.adminRole).subscribe(res => this.admins = res);
    this.userService.GetEntities(this.authService.adminId).subscribe(res =>  this.entities = res);
  }

  ngOnInit() {
    if (!this.client) {
      this.router.navigate(['/']);
    }
  }

  ngOnDestroy() {
    if (this.clientSubscribe) {
      this.clientSubscribe.unsubscribe();
    }
  }

  Disable(value) {
    this.dataLoading = true;
    this.spinner.show();

    console.log('change disable/enable');
    const disabled = value; // this.client.disabled === '1' ? '0' : '1'; // inverse the value
    this.userService.Disable(this.client, disabled)
      .subscribe((response: any) => {
        if (response.success) {
          this.client.disabled = response.disabled;
          if (this.client.disabled === '1') {
            this.toastr.success('', 'Le client a été suspendu');
          } else {
            this.toastr.success('', 'Le client a été activé');
          }
        } else {
          this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
        }
        this.dataLoading = false;
        this.spinner.hide();

      });
  }

  cancelEdit() {
    if (this.isAdmin) {
      this.router.navigate(['/client/selection']);
    } else {
      this.router.navigate(['/']);
    }
  }

  updateClient() {
    this.spinner.show();
    this.dataLoading = true;
    // save changes and return to client selection
    if (this.client.id === 0) {
      if (this.client.admin == 0) {
        // if 0 set current adminId for new customer
        this.client.admin = this.authService.adminId;
      }
      this.userService.Create(this.client)
        .subscribe((response: any) => {
          if (response.success) {
            this.toastr.success('', 'Le client a été crée');
            this.client.email_invalid = 0;
            this.client.id = response.id;
            this.userService.selectedUser$.next(this.client);
            this.router.navigate(['/client/selection']);
          } else {
            this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
          }
          this.dataLoading = false;
          this.spinner.hide();
        });
    } else {
      this.userService.Update(this.client)
        .subscribe((response: any) => {
          if (response.success) {
            this.toastr.success('Les informations ont étés modifiées', '');
            this.client.email_invalid = 0;
            if (this.isAdmin) {
              this.router.navigate(['/client/selection']);
            }
            if (this.password.password1 !== '') {
              this.authService.SetCredentials(this.client.username, this.password.password1, this.client.id);
            }
          } else {
            this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
          }
          this.dataLoading = false;
          this.spinner.hide();
        });
    }
  }

  changePostpaidOption(event) {
    this.client.option_postpaid = event.target.checked ? 1 : 0;
    if (this.client.option_postpaid === 1) {
      this.client.option_forfait = 0;
    }
  }
  changeForfaitOption(event) {
    this.client.option_forfait = event.target.checked ? 1 : 0;
    if (this.client.option_forfait === 1) {
      this.client.option_postpaid = 0;
    }
  }

  changeForfait() {
    this.newForfait = { period: this.client.forfait_period, quantity: this.client.forfait_quantity};
    this.showForfaitChange = true;
  }

  forfaitModify() {
    this.userService.ChangeForfait(this.client.id, this.newForfait).subscribe(result => {
      console.log(result);
      this.showForfaitChange = false;
      this.newForfait = { period: 0, quantity: 0};
      this.router.navigate(['/client/selection']);
    });
  }

  genkeyConfirm() {
    this.client.apikey = this.doGenKey();
    this.showSaveKey = true;
    this.showGenkeyConfirm = false;
  }

  genkey() {
    console.log('show modal genkey');
    if (this.client.id === 0) {
      this.genkeyConfirm();
    } else {
      this.showGenkeyConfirm = true;
    }
  }

  copyKey() {
    this.clipboardService.copyFromContent(this.client.apikey);
  }

  doGenKey() {
    let d = new Date().getTime();
    if (window.performance && typeof window.performance.now === 'function') {
      d += performance.now();
    }

    const uuid = 'xxxx-xxxx-xxx3-xxyxx'.replace(/[xy]/g, (c) => {
      // tslint:disable-next-line:no-bitwise
      const r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      // tslint:disable-next-line:no-bitwise
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid.toUpperCase();
  }

  selectTab(tab) {
    this.activeTab = tab;
  }

}
