export interface IAgence {
  id: number;
  customerid: number;
  name: string;
  code_agence: string;
  contact: string;
  mobile: number;
  smstext: string;
}

export class Agence {
  id: number;
  customerid: number;
  name: string;
  code_agence: string;
  contact: string;
  mobile: number;
  smstext: string;
  constructor(customerid, name, code_agence, contact, mobile, smstext) {
    this.id = 0;
    this.customerid = customerid;
    this.name = name;
    this.code_agence = code_agence;
    this.contact = contact;
    this.mobile = mobile;
    this.smstext = smstext;
    }
}
