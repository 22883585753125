
<br>
<form>
  <div class="row">
    <div class="form-group col-4">
      <div>Sélectionnez votre agence:</div>
      <select  [(ngModel)]="selectedAgence" name="code" class="agence" (ngModelChange)="selectAgence($event)">
        <option *ngFor="let agence of agences; let i=$index" [ngValue]="agence" [selected]="selectedAgence===agenge">{{agence.code_agence === '...' ? 'Toutes' : agence.code_agence + ' - ' + agence.name}}</option>
      </select>
    </div>
  </div>
</form>
<br>

<div class="clearfix">
  <form class="inline">
    <button type="button" (click)="Export('TEXT')" class="btn btn-sm btn-outline-secondary ml-2 h-100">Copy</button>
    <button type="button" (click)="Export('CSV')" class="btn btn-sm btn-outline-secondary ml-2 h-100">CSV</button>
    <button type="button" (click)="Export('EXCEL')" class="btn btn-sm btn-outline-secondary ml-2 h-100">Excel</button>
    <button type="button" (click)="Export('PRINT')" class="btn btn-sm btn-outline-secondary ml-2 h-100">Print</button>

    <div class="form-group form-inline ml-auto">
      Recherche: <input class="form-control ml-2" type="text" name="searchTerm"
        [(ngModel)]="pagingService.searchTerm" />
    </div>
  </form>


  <table id="selectedColumn" class="table table-bordered table-striped table-hover table-sm" cellspacing="0"
    width="100%">
    <thead>
      <tr>
        <th class="th" sortable="annonceid" (sort)="onSort($event)">ID</th>
        <th class="th" sortable="code_agence" (sort)="onSort($event)">Code Agence</th>
        <th class="th" sortable="reference" (sort)="onSort($event)">Référence</th>
        <th class="th" sortable="url" (sort)="onSort($event)">URL</th>
        <th class="th" sortable="smstext" (sort)="onSort($event)">Texte SMS</th>
        <th class="th" sortable="nbrequetes" (sort)="onSort($event)">#Requètes</th>
        <th class="th"></th>
      </tr>
    </thead>
    <tbody *ngIf="initialized">
      <tr *ngFor="let a of annonces$ | async">
        <td>{{a.annonceid}}</td>
        <td>
          <ngb-highlight [result]="a.code_agence" [term]="pagingService.searchTerm"></ngb-highlight>
        </td>
        <td class="collapsible">
          <ngb-highlight [result]="a.reference" [term]="pagingService.searchTerm">
          </ngb-highlight>
        </td>
        <td class="collapsible">
          <ngb-highlight [result]="a.url.substr(0,40)" [term]="pagingService.searchTerm">
          </ngb-highlight>
        </td>
        <td class="collapsible">
          <ngb-highlight [result]="a.smstext.substr(0,40)" [term]="pagingService.searchTerm">
          </ngb-highlight>
        </td>
        <td class="collapsible">
          <ngb-highlight [result]="a.nbrequetes" [term]="pagingService.searchTerm">
          </ngb-highlight>
        </td>
        <td>
          <div class="inline">
            <button type="button" title="Voir les demandes" (click)="selectAnnonce(a)"
              class="btn btn-sm btn-success mr-2 w30">
              <i class="fa fa-list"></i>
            </button>
            <button type="button" title="Editer l'annonce" (click)="editAnnonce(a)"
              class="btn btn-sm btn-primary mr-2 w30"><i class="fa fa-edit"></i></button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="inline">
    <div class="">
      Total: {{(total$ | async)}}
    </div>
    <div class="ml-auto">
      <ngb-pagination class="d-flex justify-content-end" [collectionSize]="total$ | async" [(page)]="pagingService.page"
        [boundaryLinks]="true" [rotate]="true" [directionLinks]="true" [pageSize]="pagingService.pageSize" [maxSize]="3"
        name="page">
      </ngb-pagination>
    </div>
  </div>

</div>
