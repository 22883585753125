<div class="client-view p-md-4">

  <div *ngIf="!client">
    <app-client-select [selectedUser]="client"></app-client-select>
  </div>

  <ngx-spinner bdOpacity=0.2 bdColor="rgba(51,51,51,0)" size="medium" color="#515151" type="ball-pulse-sync"
    [fullScreen]="false">
  </ngx-spinner>

  <div class="card" *ngIf="client">
    <div class="card-body">

      <h4>Historique des stages</h4>

      <ul class="nav nav-pills mb-n3" id="myTab" role="tablist">
        <li class="nav-item">
          <div class="nav-link" [ngClass]="{'active': activeTab===0}" id="tab0" data-toggle="tab" (click)="selectTab(0)"
            role="tab" aria-controls="tous" aria-selected="true">Tous les stages</div>
        </li>
        <li class="nav-item">
          <div class="nav-link" [ngClass]="{'active': activeTab===1}" id="tab1" data-toggle="tab" (click)="selectTab(1)"
            role="tab" aria-controls="details" aria-selected="false">Détails stage</div>
        </li>
        <li class="nav-item">
          <div class="nav-link" [ngClass]="{'active': activeTab===2}" id="tab2" data-toggle="tab" (click)="selectTab(2)"
            role="tab" aria-controls="nouveau" aria-selected="false">Nouveau stage</div>
        </li>
      </ul>
      <hr>

      <div class="tab-content" id="myTabContent">

        <div class="tab-pane fade show active" id="coord" role="tabpanel" aria-labelledby="coord-tab"
          *ngIf="activeTab === 0">

          <app-ade-list [client]='client'  (formation)="selectFormation($event, formation)">
          </app-ade-list>

        </div>
      </div>

      <div class="tab-content" id="myTabContent">

        <div class="tab-pane fade show active" id="coord" role="tabpanel" aria-labelledby="coord-tab"
          *ngIf="activeTab === 1">

          <app-ade-details [client]='client' [formation]="selectedFormation" (editFormation)="editFormation(selectedFormation)">
          </app-ade-details>

        </div>
      </div>

      <div class="tab-content" id="myTabContent">

        <div class="tab-pane fade show active" id="coord" role="tabpanel" aria-labelledby="coord-tab"
          *ngIf="activeTab === 2">

          <app-ade-new [client]='client' (formation)="selectFormation($event, formation)">
          </app-ade-new>

        </div>
      </div>

      <div class="tab-content" id="myTabContent">

          <div class="tab-pane fade show active" id="coord" role="tabpanel" aria-labelledby="coord-tab"
            *ngIf="activeTab === 3">

            <app-ade-edit [client]='client' [selectedFormation]="selectedFormation" (formation)="selectFormation($event, formation)">
            </app-ade-edit>

          </div>
        </div>

    </div>
  </div>
</div>

