import { Injectable } from '@angular/core';
import { Observable, empty, ReplaySubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { IUser, User } from '../interfaces/user';
import { AuthenticationService } from './authentication.service';
import { map, tap } from 'rxjs/operators';
import { IConso } from '../interfaces/consos';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  emptyUser: User = new User();
  // selectedUser: IUser = this.emptyUser;
  selectedUser$: ReplaySubject<IUser> = new ReplaySubject<IUser>(1);

  consoPeriod$: ReplaySubject<any> = new ReplaySubject<any>(1);
  consoAdmin$: ReplaySubject<any> = new ReplaySubject<any>(1);
  consoPeriodAdmin = { totalPremium: 0, totalLowcost: 0, moyLowCost: 0, moyPremium: 0 };

  postpaid$: ReplaySubject<any> = new ReplaySubject<any>(1);
  forfait$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService,
  ) {
    this.getCurrentClient();
  }


  getCurrentClient() {
    console.log('user service ctor');
    if (!this.authService.isAdmin) {
      // load user information
      const loggedUser = JSON.parse(sessionStorage.getItem('currentUser'));
      this.GetById(loggedUser.userId).subscribe((user: IUser) => {
        // this.selectedUser = user;
        this.selectedUser$.next(user);
      });
    }
  }

  GetCreditsHistory(clientid, period): Observable<any> {
    return this.httpClient.post(environment.apiUrl + '/clients/credithistory/' + clientid, period).pipe(
      tap((res: any) => {
        if (res.success) {
        this.consoPeriod$.next ( { premium: res.premium, lowcost: res.lowcost });
        }
      }),
      map((res: any) =>  res.history )
    );
  }
  GetCreditsHistoryAll(): Observable<any> {
    return this.consoPeriod$;
  }
  GetCreditsAdminHistory(period): Observable<any> {
    console.log('GetCreditsAdminHistory');
    return this.httpClient.post(environment.apiUrl + '/credits/adminhistory', period).pipe(
      tap( (res: any) => {
        console.log(res);
        if (res.success) {
          this.consoPeriod$.next ( {
            totalPremium: res.totalPremium,
            totalLowcost: res.totalLowcost,
            moyLowCost: res.moyLowCost,
            moyPremium: res.moyPremium,
          });
          this.consoAdmin$.next(res.conso);
          }
        }),
      map((res: any) =>  res.credits )
    );
  }

  GetConsoAdmin() {
    return this.consoAdmin$;
  }


  GetCreditsPerAdmin(adminid, period): Observable<any> {
    return this.httpClient.post(environment.apiUrl + '/credits/peradmin/' + adminid, period);
  }


  GetCreditsRecap(adminid): Observable<any> {
    return this.httpClient.post(environment.apiUrl + '/credits/adminrecap/' + adminid, {});
  }

  GetCreditsPostpaid(adminid): Observable<any> {
    return this.httpClient.post(environment.apiUrl + '/credits/adminpostpaid/' + adminid, {}).pipe(
      tap((res: any) => {
        if (res.success) {
          this.postpaid$.next( { totalPremium: res.totalPremium, totalLowcost: res.totalLowcost});
        }
      }),
      map((res: any) =>  res.credits )
    );
  }
  GetPostPaidCounter(): Observable<any> {
    return this.postpaid$;
  }


  SendPostpaidMail(uid, uname): Observable<any> {
    return this.httpClient.post(environment.apiUrl + '/credits/sendpostpaidmail', { userid: uid, username: uname });
  }

  ResetPostpaidCredits(uid, uname): Observable<any> {
    return this.httpClient.post(environment.apiUrl + '/credits/resetcredits', { userid: uid, username: uname });
  }

  GetCreditsForfait(adminid): Observable<any> {
    return this.httpClient.post(environment.apiUrl + '/credits/adminforfait/' + adminid, {}).pipe(
      tap((res: any) => {
        if (res.success) {
          this.forfait$.next( { totalPremium: res.totalPremium, totalLowcost: res.totalLowcost});
        }
      }),
      map((res: any) =>  res.credits )
    );
  }
  GetForfaitCounter(): Observable<any> {
    return this.forfait$;
  }

  GetEntities(uid): Observable<any> {
    return this.httpClient.get(environment.apiUrl + '/clients/entities/' + uid);
  }

  SendForfaitMail(uid, uname): Observable<any> {
    return this.httpClient.post(environment.apiUrl + '/credits/sendforfaitmail', { userid: uid, username: uname });
  }

  ResetForfaitCredits(uid, uname): Observable<any> {
    return this.httpClient.post(environment.apiUrl + '/credits/resetforfaitcredits', { userid: uid, username: uname });
  }


  AddAdmin(admin): Observable<any> {
    return this.httpClient.post(environment.apiUrl + '/admins/add', admin);
  }

  UpdateAdmin(admin): Observable<any> {
    return this.httpClient.put(environment.apiUrl + '/admins/update/' + admin.id, admin);
  }

  DeleteAdmin(adminid): Observable<any> {
    return this.httpClient.delete(environment.apiUrl + '/admins/' + adminid);
  }

  GetAdmins(adminid, role): Observable<any> {
    return this.httpClient.post(environment.apiUrl + '/admins/getall', { id: adminid, role });
  }

  GetAll(adminid): Observable<any> {
    return this.httpClient.get(environment.apiUrl + '/clients/getall/' + adminid).pipe(tap((clients: any[]) => {
      clients.forEach( c => {
        c.suspendu = c.disabled === '1' ? 'Désactivé' : ( c.disabled === '2' ? 'En attente de validation' : '');
      });
    }));
  }

  GetMyClients(id): Observable<any> {
    return this.httpClient.get(environment.apiUrl + '/clients');
  }

  GetById(id) {
    return this.httpClient.get(environment.apiUrl + '/clients/' + id).pipe(
      tap( user => this.selectedUser$.next(user as IUser))
    );
  }

  GetByUsername(username) {
    return this.httpClient.get(environment.apiUrl + '/clients/username/' + username);
  }

  Create(user) {
    return this.httpClient.post(environment.apiUrl + '/clients/create', user);
  }

  Update(user) {
    return this.httpClient.put(environment.apiUrl + '/clients/' + user.id, user);
  }

  changePassword(user, password) {
    return this.httpClient.put(environment.apiUrl + '/clients/changepassword/' + user.id, password);
  }

  Delete(user) {
    return this.httpClient.put(environment.apiUrl + '/clients/setdeleted/' + user.id, {});
  }

  Disable(user, disabled) {
    return this.httpClient.put(environment.apiUrl + '/clients/setdisabled/' + user.id + '/' + disabled, {});
  }

  DeleteID(userid) {
    return this.httpClient.delete(environment.apiUrl + '/clients/' + userid);
  }

  ChangeForfait(uid, forfait): Observable<any> {
    return this.httpClient.put(environment.apiUrl + '/clients/changeforfait/' + uid, forfait);
  }

  GetConso(userid, periodlen) {
    return this.httpClient.get(environment.apiUrl + '/clients/conso/' + userid + '/' + periodlen);
  }

  GetModeles(userid, showlast) {
    return this.httpClient.get(environment.apiUrl + '/modeles/user/' + userid + '/' + showlast);
  }

  AddModele(userid, modele) {
    return this.httpClient.post(environment.apiUrl + '/modeles/' + userid, modele);
  }

  UpdateModele(userid, modele) {
    return this.httpClient.put(environment.apiUrl + '/modeles/' + userid, modele);
  }

  DeleteModele(userid, modeleid) {
    return this.httpClient.delete(environment.apiUrl + '/modeles/' + userid + '/' + modeleid);
  }


  // -------------------------------------------
  //  for all
  PasswordRequest(username) {
    return this.httpClient.get(environment.apiUrl + '/clients/passwordrequest/' + username);
  }

  VerifyChecksum(username, checksum) {
    return this.httpClient.get(environment.apiUrl + '/clients/verifychecksum/' + username + '/' + checksum);
  }

  Activate(username, checksum) {
    return this.httpClient.get(environment.apiUrl + '/clients/activate/' + username + '/' + checksum);
  }

  ChangeUsernamePassword(username, checksum, password) {
    return this.httpClient.get(environment.apiUrl + '/clients/changepassword/' + username + '/' + checksum + '/' + password);
  }

  Logout() {
    return this.httpClient.get(environment.apiUrl + '/logout');
  }

  adminLogout() {
    return this.httpClient.get(environment.apiUrl + '/adminlogout');
  }


  // -------------------------------------------
  //  add credits  : admin only

  AddCredits(clientid, prio, quantity) {
    return this.httpClient.post(environment.apiUrl + '/clients/credits/' + clientid, { priority: prio, quantity });
  }


}
