import { Component, OnInit } from '@angular/core';
declare var $;

import { IUser } from '../../interfaces/user';
import { EventManagerService } from 'src/app/providers/event-manager.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {

  client: IUser = null;

  constructor(
    private eventManager: EventManagerService,
    private router: Router,
  ) {
    this.eventManager.bind(this.eventManager.eventUserSelected, (user) => { this.client = user; });
  }

  ngOnInit(): void {
  }

}
