<div class="alert alert-secondary select-user" *ngIf="authService.isAdmin">

    <div class="btn btn-info pull-right"  style="margin-top:-9px" [routerLink]="['/client/selection']">Selectionner</div>
    <div *ngIf="!selectedUser">Sélectionnez un client ...
    </div>
    <div *ngIf="selectedUser">
        <div>
        Client sélectionné: <b>{{selectedUser.company}} {{selectedUser.firstname}} {{selectedUser.name}}</b>
         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Crédits SMS: Premium <b>{{selectedUser.premium}}</b> / Lowcost <b>{{selectedUser.lowcost}}</b>
         </div>
    </div>
</div>
