<div class="client-view p-md-4">
  <ngx-spinner bdOpacity=0.2 bdColor="rgba(51,51,51,0)" size="medium" color="#515151" type="ball-pulse-sync"
    [fullScreen]="false">
  </ngx-spinner>

  <app-client-select [selectedUser]="client"></app-client-select>

  <div class="card">
    <div class="card-body">

      <div *ngIf="campaign.priority<=1">
        <form role="form" name="campform" id="campform" #f="ngForm">
          <ul class="nav nav-pills mb-n3" id="myTab" role="tablist">
            <li class="nav-item">
              <div class="nav-link" [ngClass]="{'active': activeTab===0}" id="tab0" data-toggle="tab"
                (click)="selectTab(0)" role="tab" aria-controls="information" aria-selected="true">1 - Information</div>
            </li>
            <li class="nav-item">
              <div class="nav-link" [ngClass]="{'active': activeTab===1}" id="tab1" data-toggle="tab"
                (click)="selectTab(1)" role="tab" aria-controls="caracteristique" aria-selected="false">2
                - Caractéristiques</div>
            </li>
            <li class="nav-item">
              <div class="nav-link" [ngClass]="{'active': activeTab===2}" id="tab2" data-toggle="tab"
                (click)="selectTab(2)" role="tab" aria-controls="planification" aria-selected="false">3 -
                Planification</div>
            </li>
            <li class="nav-item" *ngIf="campaign.asap == 2">
              <div class="nav-link" [ngClass]="{'active': activeTab===3}" id="tab3" data-toggle="tab"
                (click)="selectTab(3)" role="tab" aria-controls="sequence" aria-selected="false">4 -
                Séquencement</div>
            </li>
          </ul>
          <hr>

          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active p-4" id="information" role="tabpanel" aria-labelledby="information"
              *ngIf="activeTab === 0">

              <h4 class="txt18 bold">Nom de la campagne:</h4>
              <div class="form-group col-xs-6">
                <input type="text" name="name" id="name1" class="form-control" [(ngModel)]="campaign.name"
                  autocomplete="off" autocapitalize="off" autocorrect="off" required #nameRef="ngModel" required />
                <span *ngIf="nameRef.dirty && nameRef.errors?.required" class="text text-danger">Champ
                  obligatoire</span>
              </div>

              <br>

              <h4 class="txt18 bold">Choix des listes de contacts :</h4>
              <div class="row">
                <div class="col-xs-5 list-container">
                  Disponibles
                  <ul class="list-group">
                    <ng-container *ngFor="let list of lists">
                      <li *ngIf="list.selected==0" class="list-group-item" (click)="listSelect(list)">
                        <div class="badge badge-pill badge-secondary float-right">{{list.actif}} / {{list.count}}</div>
                        <div class="list-name">{{list.name}}</div>
                        <div class="list-field" style="overflow:hidden">{{list.fields}}</div>
                      </li>
                    </ng-container>
                  </ul>
                </div>
                <div class="exchange">
                  <i class="fa fa-exchange"></i>
                </div>
                <div class="col-xs-5 list-container">
                  Sélectionnées
                  <ul class="list-group">
                    <ng-container *ngFor="let list of lists">
                      <li *ngIf="list.selected==1" class="list-group-item" (click)="listUnselect(list)">
                        <div class="btn btn-info btn-sm float-right btn-filter" (click)="selectActif($event, list)">
                          Filtrer</div>
                        <div class="badge badge-pill badge-secondary float-right">{{list.actif}} / {{list.count}}</div>
                        <div class="list-name">{{list.name}}</div>
                        <div class="list-field" style="overflow:hidden">{{list.fields}}</div>
                      </li>
                    </ng-container>
                  </ul>
                </div>
              </div>
              <br>
              <div class="col-xs-12">Champs variables disponibles:</div>
              <div class="alert alert-info" style="background:#d9edf7">{{fields}}</div>
            </div>
          </div>

          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="caracteristique" role="tabpanel"
              aria-labelledby="caracteristique" *ngIf="activeTab === 1">

              <h3>{{campaign.name}}</h3>
              <div class="row">
                <div class="col-xs-12 col-sm-8">
                  <div class="form-group">
                    <div class="row" style="height:42px;">
                      <div class="col-6">
                        <div *ngIf="modeles.length>0">
                          <select name="modele" id="modele" class="form-control" [(ngModel)]="modele"
                            (change)="fillWithModele()">
                            <option [value]="modeleDefault" selected>{{modeleDefault.label}}</option>
                            <option *ngFor="let m of modeles" [ngValue]="m">{{m.label}}</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-6 mt-2">
                        <div class="text text-primary" (click)="addModele()">Enregistrer comme modèle</div>
                      </div>
                    </div>
                    <textarea *ngIf="campaign.priority==1"
                      [(ngModel)]="campaign.message"
                      class="form-control counted"
                      style="resize:vertical;margin-bottom:10px;"
                      name="message"
                      placeholder="Saisissez votre message  -  Utilisez le sélecteur à droite pour les champs variables  -  Ajoutez STOP pour les messages commerciaux - Ajoutez REPONSE si vous attendez des réponses"
                      rows="5"
                      (ngModelChange)="computeMsgLen()">
                    </textarea>
                    <textarea *ngIf="campaign.priority==0"
                      [(ngModel)]="campaign.message"
                      class="form-control counted"
                      style="resize:vertical;margin-bottom:10px;"
                      name="message"
                      placeholder="Saisissez votre message  -  Ajoutez STOP pour les messages commerciaux" rows="5"
                      (ngModelChange)="computeMsgLen()">
                    </textarea>
                    <span>Nombre de caractéres estimés: {{computed.long}} / </span><span class="text text-primary"
                      [ngClass]="{'text-danger':computed.sms>1}">{{computed.sms}} SMS</span>
                    <span class="text text-danger" *ngIf="campaign.priority==0 && 160<computed.long"><br>
                      Priorité Lowcost: Longueur MAX 160 carac. Votre message sera tronqué !
                    </span>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-4 text-center">
                  <div style="height: 25px"></div>
                  <div *ngIf="fields.length>0 && campaign.priority==1">
                    <label>Champs disponibles</label>
                    <select name="fields" id="fields" class="form-control" [(ngModel)]="fieldToAdd"
                      (ngModelChange)="addMsgField()">
                      <option *ngFor="let f of fields" [ngValue]="f">{{f}}</option>
                    </select>
                  </div>
                  <div style="height: 10px"></div>
                  <div class="btn btn-info" (click)="addMsgStop()">Ajouter STOP</div>
                  <div *ngIf="campaign.priority==1" class="btn btn-info" (click)="addMsgReponse()">Ajouter
                    REPONDRE</div>
                  <div style="height: 10px"></div>
                  <div *ngIf="campaign.hasURL" class="btn btn-primary" (click)="addShortUrl()">Insérer lien internet
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xs-12 col-sm-6">
                  <div class="form-group">
                    <label for="addresssuite">Priorité</label>
                    <select name="priority" id="priority" class="form-control" [(ngModel)]="campaign.priority"
                      (ngModelChange)="priorityChange()">
                      <option value="0" [selected]="campaign.priority==0" *ngIf="client.option_lowcost==1">Lowcost
                      </option>
                      <option value="1" [selected]="campaign.priority==1">Premium</option>
                      <option value="2" [selected]="campaign.priority==2">Unitaire</option>
                    </select>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-6" *ngIf="campaign.priority==1">
                  <div class="form-group">
                    <label for="fromname">Expéditeur SMS</label>
                    <input type="text" name="fromname" [(ngModel)]="campaign.fromname" class="form-control"
                      autocomplete="off" autocapitalize="off" autocorrect="off" minlength="3" maxlength="11"
                      appPattern="[^a-zA-Z0-9\-_+]*" #fromnameRef="ngModel" />
                    <div *ngIf="fromnameRef.errors?.pattern" class="text text-danger">Le format doit être: une lettre en premier et entre 3 et 11 caractères, pas d'espace
                    </div>
                    <div *ngIf="fromnameRef.errors?.minlength" class="text text-danger">Au moins 3 caractères</div>
                  </div>

                </div>
              </div>

              <div class="row">
                <div class="col-xs-12 col-sm-8">
                  <div class="form-group">
                    <label for="endreceipt1">Email d'information</label>
                    <div style="margin-bottom: 25px" class="input-group">
                      <span class="input-group-addon btn btn-grey"><i class="fa fa-at"></i></span>
                      <input type="email" name="endreceipt" id="endreceipt1" class="form-control"
                        [(ngModel)]="campaign.endreceipt" placeholder="votre.nom@email.com" autocomplete="off"
                        autocapitalize="off" autocorrect="off" />
                    </div>
                  </div>
                </div>
              </div>

              <br>

            </div>
          </div>

          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="planification" role="tabpanel" aria-labelledby="planification"
              *ngIf="activeTab === 2">

              <h3>{{campaign.name}}</h3>

              <div class="row">
                <div class="checkbox checkbox-primary offset-1">
                  <input type="checkbox" [checked]="campaign.asap == 1" id="asap" name="asap"
                    (change)="campaign.asap = $event.target.checked ? 1 : 0; selectSeqTab()">
                  <label for="asap">
                    <h4>&nbsp;&nbsp;Démarrage dès que possible</h4>
                  </label>
                </div>
              </div>

              <hr>
              <div class="row">
                <div class="checkbox checkbox-primary offset-1">
                  <input type="checkbox" [checked]="campaign.asap == 0" id="planned" name="planned"
                    (change)="campaign.asap = $event.target.checked ? 0 : 1; selectSeqTab()">
                  <label for="planned">
                    <h4>&nbsp;&nbsp;Démarrage planifié</h4>
                  </label>
                </div>
              </div>
              <div class="row">
                <div class=" form-group offset-md-1 col-sm-7 col-md-4">
                  <label for="startdate" class="mt-1 mr-2">Date d'envoi</label>
                  <div class="input-group">
                    <input class="form-control" placeholder="yyyy-mm-dd" name="startdate"
                      [(ngModel)]="campaign.startdate" ngbDatepicker #d1="ngbDatepicker" (click)="d1.toggle()">
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary btn-grey" (click)="d1.toggle()" type="button"><i
                          class="fa fa-calendar"></i></button>
                    </div>
                  </div>
                </div>

                <div class="form-group col-xs-12 col-sm-5 col-md-4">
                  <label class="ml-5">Heure</label>
                  <div class="input-group" style="margin-top:-34px">
                    <ngb-timepicker [(ngModel)]="campaign.starttime" name="starttime"></ngb-timepicker>
                  </div>
                </div>
              </div>

              <div *ngIf="campaign.priority==1 && client?.option_sequence==1">
                <hr>
                <div class="row">
                  <div class="checkbox checkbox-primary offset-1">
                    <input type="checkbox" [checked]="campaign.asap == 2"
                      (change)="campaign.asap = $event.target.checked ? 2 : 1; selectSeqTab()" id="sequenced"
                      name="sequenced">
                    <label for="sequenced">
                      <h4>&nbsp;&nbsp;Envoi séquencé</h4>
                    </label>
                  </div>
                </div>
              </div>
              <br>
              <br>
              <br>

            </div>
          </div>

          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="sequencement" role="tabpanel" aria-labelledby="sequencement"
              *ngIf="activeTab === 3">

              <h4>Envoi séquencé</h4>

              <div class="offset-md-1 ">
                <div class="col-xs-12" style="margin-bottom:10px">
                  <label for="nbperperiod">Nombre de messages à envoyer par minute</label>
                  <div class="input-group col-xs-12 col-sm-6 col-md-6">
                    <input type="text" name="nbperperiod" id="nbperperiod" class="form-control"
                      [(ngModel)]="campaign.seq_nb_per_period" (ngModelChange)="updateSeqNbPerPeriod()"
                      autocomplete="off" autocapitalize="off" autocorrect="off" (mouseup)="$event.target.select()" />
                  </div>
                </div>
                <div class="h5">ou</div>
                <div class="col-xs-12">
                  <label for="nbperperiod">Délai entre chaque message (en minutes)</label>
                  <div class="input-group col-xs-12 col-sm-6 col-md-6">
                    <input type="text" name="delay" id="delay" class="form-control" [(ngModel)]="campaign.seq_delay"
                      (ngModelChange)="updateSeqDelay()" autocomplete="off" autocapitalize="off" autocorrect="off"
                      (mouseup)="$event.target.select()" />
                  </div>
                </div>
              </div>
              <br>
              <br>
              <br>
              <div class="h5 text text-info">{{campaign.total_contact}} contacts / durée estimée:
                {{campaign.seq_duration}}
              </div>

              <br>
              <hr>
              <br>
              <h4>Envoi planifié de {{campaign.total_contact}} contacts</h4>


              <div class="alert"
                [ngClass]="campaign.seq_hduration <= campaign.seq_available ? 'alert-success' : 'alert-danger'">
                Durée nécessaire : {{campaign.seq_hduration}}
                &nbsp; &nbsp; &nbsp;
                Durée disponible : {{campaign.seq_available}}
              </div>

              <br>
              <hr>
              <br>

              <label for="weekday">Jours disponibles</label>

              <div class="w-100 overflow-auto">
                <div class="column4" *ngFor="let day of campaign?.schedule">

                  <div class="sched_day col-xs-2">
                    {{day?.label}}
                  </div>
                  <div class="sched_day col-xs-2" (click)="RemoveDay(day)"
                    style="color:red; width:10px; float:right; cursor:pointer">x</div>
                  <div class="sched_selected btn btn-sm" [ngClass]="day?.checked=='0' ? 'btn-secondary' : 'btn-success'"
                    (click)="SchedSelectDay(day)">
                    {{day?.available}}h
                  </div>
                  <div class="sched_hour">
                    <div class="btn btn-sm pt-2" *ngFor="let hour of day?.timerange"
                      [ngClass]="hour.checked=='0' ? 'btn-default' : ( day.checked=='0' || hour.checked=='2' ? 'btn-outline-success' : 'btn-success')"
                      (click)="day.checked=='0' ||SchedSelectHour($event, day, hour)">
                      {{hour?.label}}
                    </div>
                  </div>
                </div>
              </div>
              <br>
              <div class="d-flex">
                <div class="btn btn-info btn-sm mr-2 p-2" (click)="d2.toggle()"><i
                    class="fa fa-plus-square-o"></i>&nbsp;&nbsp;Ajouter un jour</div>
                <input class="w-25" placeholder="yyyy-mm-dd" name="schedNewDay" [(ngModel)]="schedNewDay" ngbDatepicker
                  #d2="ngbDatepicker" (dateSelect)="SchedAddDay()" (keyup.enter)="SchedAddDay()" (click)="d2.toggle()">
              </div>
              <br>
              <br>
              <br>
            </div>

            <div style="clear:both;"></div>


            <div class="form-actions">

              <button [disabled]="activeTab==0" class="btn btn-success" (click)="prevTab()"><i class="fa fa-right"></i>&nbsp;Précédent</button>
              <button *ngIf="activeTab < maxTab" class="btn btn-success ml-2" (click)="nextTab()"><i
                  class="fa fa-right"></i>&nbsp;Suivant</button>

              <button *ngIf="activeTab==maxTab" (click)="updateCampaign()" class="btn btn-primary ml-2"
                [ngClass]="{'btn-success': campform?.dirty}">
                <i class="fa fa-save"></i>&nbsp;
                <span *ngIf="campaign.id>0">Modifier ma campagne</span>
                <span *ngIf="campaign.id==0">Créer ma campagne</span>
              </button>

              <div *ngIf="activeTab==0" class="btn btn-success pull-right" (click)="uploadList()">Charger une liste
              </div>

            </div>
          </div>
        </form>
      </div>

<!-- -------- campagne unitaire ------- -->
      <div *ngIf="campaign.priority==2">
        <form role="form" name="campform" id="campform" #f="ngForm">

          <ul class="nav nav-pills mb-n3" id="myTab" role="tablist">
            <li class="nav-item">
              <div class="nav-link" [ngClass]="{'active': activeTab===0}" id="tab1" data-toggle="tab"
                (click)="selectTab(0)" role="tab" aria-controls="caracteristique" aria-selected="false">1
                - Caractéristiques</div>
            </li>
            <li class="nav-item">
              <div class="nav-link" [ngClass]="{'active': activeTab===1}" id="tab2" data-toggle="tab"
                (click)="selectTab(1)" role="tab" aria-controls="planification" aria-selected="false">2 -
                Planification</div>
            </li>
          </ul>
          <hr>

          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="caracteristique" role="tabpanel"
              aria-labelledby="caracteristique" *ngIf="activeTab === 0">
              <br>
              <h4 class="txt18 bold">Nom de la campagne:</h4>
              <div class="form-group col-xs-6">
                <input type="text" name="name" id="name1" class="form-control" [(ngModel)]="campaign.name"
                  autocomplete="off" autocapitalize="off" autocorrect="off" required #nameRef="ngModel" required />
                <span *ngIf="nameRef.dirty && nameRef.errors?.required" class="text text-danger">Champ
                  obligatoire</span>
              </div>

              <br>
              <div class="row">
                <div class="col-xs-12 col-sm-8">
                  <div class="form-group">
                    <div class="row" style="height:42px;">
                      <div class="col-6">
                        <div *ngIf="modeles.length>0">
                          <select name="modele" id="modele" class="form-control" [(ngModel)]="modele"
                            (change)="fillWithModele()">
                            <option [value]="modeleDefault" selected>{{modeleDefault.label}}</option>
                            <option *ngFor="let m of modeles" [ngValue]="m">{{m.label}}</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-6 mt-2">
                        <div class="text text-primary" (click)="addModele()">Enregistrer comme modèle</div>
                      </div>
                    </div>

                    <textarea [(ngModel)]="campaign.message" class="form-control counted"
                      style="resize:vertical;margin-bottom:10px;" name="message"
                      placeholder="Saisissez votre message  -  Ajoutez STOP pour les messages commerciaux" rows="5"
                      (ngModelChange)="computeMsgLen()">
                      </textarea>
                    <span>Nombre de caractéres estimés: {{computed.long}} / </span><span class="text text-primary"
                      [ngClass]="{'text-danger':computed.sms>1}">{{computed.sms}} SMS</span>
                    <span class="text text-danger" *ngIf="campaign.priority==0 && 160<computed.long"><br>
                      Priorité Lowcost: Longueur MAX 160 carac. Votre message sera tronqué !
                    </span>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-4 text-center">
                  <div style="height: 25px"></div>
                  <div class="btn btn-info" (click)="addMsgStop()">Ajouter STOP</div>
                  <div *ngIf="campaign.priority==1" class="btn btn-info" (click)="addMsgReponse()">Ajouter
                    REPONDRE</div>
                  <div style="height: 10px"></div>
                  <div *ngIf="campaign.hasURL" class="btn btn-primary" (click)="addShortUrl()">Insérer lien internet
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xs-12 col-sm-6">
                  <div class="form-group">
                    <label for="destinataires">Liste de destinataires</label>
                    <textarea [(ngModel)]="campaign.destination_list" class="form-control counted"
                      style="resize:vertical;margin-bottom:10px;" name="destinataires"
                      placeholder="Numéros de mobiles destinataires séparés par ';'" rows="3">
                    </textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-12 col-sm-6">
                  <div class="form-group">
                    <label for="fromname">Expéditeur SMS</label>
                    <input type="text" name="fromname" [(ngModel)]="campaign.fromname" class="form-control"
                      autocomplete="off" autocapitalize="off" autocorrect="off" minlength="3" maxlength="11"
                      appPattern="[^a-zA-Z0-9\-_+]*" #fromnameRef="ngModel" />
                    <div *ngIf="fromnameRef.errors?.pattern" class="text text-danger">Le format doit être: une lettre en premier et entre 3 et 11 caractères, pas d'espace
                    </div>
                    <div *ngIf="fromnameRef.errors?.minlength" class="text text-danger">Au moins 3 caractères</div>
                  </div>

                </div>
              </div>

              <div class="row">
                <div class="col-xs-12 col-sm-8">
                  <div class="form-group">
                    <label for="endreceipt1">Email d'information</label>
                    <div style="margin-bottom: 25px" class="input-group">
                      <span class="input-group-addon btn btn-grey"><i class="fa fa-at"></i></span>
                      <input type="email" name="endreceipt" id="endreceipt1" class="form-control"
                        [(ngModel)]="campaign.endreceipt" placeholder="votre.nom@email.com" autocomplete="off"
                        autocapitalize="off" autocorrect="off" />
                    </div>
                  </div>
                </div>
              </div>

              <br>

            </div>
          </div>

          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="planification" role="tabpanel" aria-labelledby="planification"
              *ngIf="activeTab === 1">
              <br>
              <h3>{{campaign.name}}</h3>

              <div class="row">
                <div class="checkbox checkbox-primary offset-1">
                  <input type="checkbox" [checked]="campaign.asap == 1" id="asap" name="asap"
                    (change)="campaign.asap = $event.target.checked ? 1 : 0">
                  <label for="asap">
                    <h4>&nbsp;&nbsp;Démarrage dès que possible</h4>
                  </label>
                </div>
              </div>

              <hr>
              <div class="row">
                <div class="checkbox checkbox-primary offset-1">
                  <input type="checkbox" [checked]="campaign.asap == 0" id="planned" name="planned"
                    (change)="campaign.asap = $event.target.checked ? 0 : 1">
                  <label for="planned">
                    <h4>&nbsp;&nbsp;Démarrage planifié</h4>
                  </label>
                </div>
              </div>
              <div class="row">
                <div class=" form-group offset-md-1 col-sm-7 col-md-4">
                  <label for="startdate" class="mt-1 mr-2">Date d'envoi</label>
                  <div class="input-group">
                    <input class="form-control" placeholder="yyyy-mm-dd" name="startdate"
                      [(ngModel)]="campaign.startdate" ngbDatepicker #d2="ngbDatepicker" (click)="d2.toggle()">
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary btn-grey" (click)="d2.toggle()" type="button"><i
                          class="fa fa-calendar"></i></button>
                    </div>
                  </div>
                </div>

                <div class="form-group col-xs-12 col-sm-5 col-md-4">
                  <label class="ml-5">Heure</label>
                  <div class="input-group" style="margin-top:-34px">
                    <ngb-timepicker [(ngModel)]="campaign.starttime" name="starttime"></ngb-timepicker>
                  </div>
                </div>
              </div>

              <br>
              <br>
              <br>

            </div>
          </div>


          <div style="clear:both;"></div>


          <div class="form-actions">

            <button [disabled]="activeTab==0" class="btn btn-success" (click)="prevTab()"><i class="fa fa-right"></i>&nbsp;Précédent</button>
            <button *ngIf="activeTab < maxTab2" class="btn btn-success ml-2" (click)="nextTab()"><i
                class="fa fa-right"></i>&nbsp;Suivant</button>

            <button *ngIf="activeTab==maxTab2" (click)="updateCampaign()" class="btn btn-primary ml-2"
              [ngClass]="{'btn-success': campform?.dirty}">
              <i class="fa fa-save"></i>&nbsp;
              <span *ngIf="campaign.id>0">Modifier ma campagne</span>
              <span *ngIf="campaign.id==0">Créer ma campagne</span>
            </button>

          </div>
        </form>
      </div>

    </div>
  </div>


</div>


<app-modal [(isActive)]="showListFilterModal" [overlayDismiss]="true" (overlayClick)="showListFilterModal=false"
  [isXLarge]="true" class="modal-dialog modal-dialog-scrollable">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Sélection des contacts actifs</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="showListFilterModal=false">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">

      <div class="p-20">
        <div class="h4">{{list?.name}}</div>
        <table class="table table-nolines">
          <tr>
            <td class="filter-fields">Champs: {{list?.fields}}</td>
            <td>Total: {{list?.count}}</td>
            <td>Actifs: {{list?.actif}}</td>
          </tr>
        </table>

        <div class="btn btn-primary btn-sm" (click)="ContactSelectAll()">Tout activer</div>
        <div class="btn btn-danger btn-sm" (click)="ContactClearAll()">Tout désactiver</div>
        <div class="pull-right">
          <label for="filter">Recherche</label>
          <input type="text" name="filter" [(ngModel)]="filter" (ngModelChange)="filterChange()">
        </div>

      </div>
      <div class="contact-list">
        <table class="table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Données</th>
              <th>Actif</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let c of list?.contacts; let i = index">
              <tr class="contact-actif" [ngClass]="{'contact-inactif': c.selected==0}"
                *ngIf="list.visible[i] && c.json_data.toLowerCase().indexOf(filter.toLowerCase())!==-1">
                <td>{{c.id}}</td>
                <td>{{c.json_data}}</td>
                <td>
                  <div *ngIf="c.selected==1" class="btn btn-sm btn-success" (click)="ContactClear(i)"><i
                      class="fa fa-check"></i></div>
                  <div *ngIf="c.selected==0" class="btn btn-sm btn-info" (click)="ContactSelect(i)"><i
                      class="fa fa-minus"></i></div>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>

    <div class="modal-footer">
      <button class="btn btn-secondary pull-left" (click)="showListFilterModal=false">Annuler</button>
      <button class="btn btn-primary" (click)="applyFilter()">Valider cette sélection ({{list?.actif}}
        actifs)</button>
    </div>
  </div>
</app-modal>

<app-modal [(isActive)]="showAddModele" [overlayDismiss]="true" (overlayClick)="showAddModele=false" [isXLarge]="true"
  class="modal-dialog modal-dialog-scrollable">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Création modèle de message</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="showAddModele=false">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="overflow-x: hidden">
      <form class="p-3">
        <div class="form-group col-12">
          <label for="label">Saisissez le titre du modèle</label>
          <input type="text" name="label" id="labelmobile" class="form-control col-12" [(ngModel)]="modeleLabel"
            autocomplete="off" autocapitalize="off" autocorrect="off" required #labelRef="ngModel">
          <div [style.visibility]="labelRef.errors?.required ? 'visible' : 'hidden'" class="text text-danger">Saisissez
            un titre</div>
        </div>
      </form>
    </div>

    <div class="modal-footer">
      <button class="btn btn-secondary pull-left" (click)="showAddModele=false">Annuler</button>
      <button class="btn btn-primary" (click)="storeAsModele()" [disabled]="labelRef.errors?.required">Ajouter</button>
    </div>
  </div>
</app-modal>
