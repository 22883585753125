

import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/providers/user.service';
import { IUser } from 'src/app/interfaces/user';
import { AllianceDistributionService } from 'src/app/providers/alliance-distribution.service';
import { CampaignService } from 'src/app/providers/campaign.service';

import moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-ade-menu',
  templateUrl: './ade-menu.component.html',
  styleUrls: ['./ade-menu.component.scss']
})
export class AdeMenuComponent implements OnInit {

  client: IUser;
  activeTab = 0;
  campaign: any;

  selectedFormation = null;

  constructor(
    private userService: UserService,
    private adeService: AllianceDistributionService,

  ) { }

  ngOnInit() {
    this.userService.selectedUser$.subscribe(user => {
      this.client = user;
    });
    this.selectTab(0);  // display all MLP campaign
  }

  selectTab(tab) {
    if (this.activeTab !== tab) {
      this.activeTab = tab;
      return;
    }
    this.activeTab = -1;
    setTimeout(() => {
      this.activeTab = tab;
    }, 0);
  }

  selectFormation(formation) {
    console.log('selectFormation', formation);
    this.selectedFormation = formation;
    this.selectTab(1);   // display selected formation details
  }

  editFormation(formation) {
    console.log('editFormation', formation);
    this.selectedFormation = formation;
    this.selectTab(3);   // display selected formation details
  }

}
