import { Component, OnInit, Input, ViewChildren, QueryList, Injectable, Output, EventEmitter } from '@angular/core';
import { UserService } from 'src/app/providers/user.service';
import { IUser } from 'src/app/interfaces/user';
import { AuthenticationService } from 'src/app/providers/authentication.service';
import { IAdeFormation, IAdeStagiaire } from 'src/app/interfaces/ade';

import moment from 'moment';
import * as R from 'ramda';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbdSortableHeader, SortEvent } from 'src/app/directives/sortable.directive';
import { PagingService } from 'src/app/providers/paging.service';
import { ExportDataService } from 'src/app/providers/export-data.service';
import { Observable, of } from 'rxjs';
import { NgbDateAdapter, NgbDateStruct, NgbDateNativeAdapter, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { AllianceDistributionService } from 'src/app/providers/alliance-distribution.service';
import { CampaignService } from 'src/app/providers/campaign.service';
import { ToastrService } from 'ngx-toastr';
import { ContactListService } from 'src/app/providers/contact-list.service';


@Component({
  selector: 'app-ade-details',
  templateUrl: './ade-details.component.html',
  styleUrls: ['./ade-details.component.scss'],
})
export class AdeDetailsComponent implements OnInit {


  @Input() client: IUser = null;
  @Input() formation: IAdeFormation = null;

  @Output() editFormation: EventEmitter<any> = new EventEmitter<any>();

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  page = 1;
  pageSize = 10;
  collectionSize = 1;
  searchFields = ['id', 'nom', 'prenom', 'mobile', 'reponse', 'commentaires', 'date_reponse', 'date_last_sms'];

  stagiaires$: Observable<IAdeStagiaire[]>;
  total$: Observable<number>;
  loading$: Observable<boolean>;
  initialized = false;

  list = { id: 0, fields: '' };
  testVars = {
    mobile: '',
    fields: [],
    mandatory: [],
    values: []
  };
  showAddStagiaire = false;

  constructor(
    public pagingService: PagingService<IAdeStagiaire>,
    private exportDataService: ExportDataService,
    private adeService: AllianceDistributionService,
    private spinner: NgxSpinnerService,
    private campaignService: CampaignService,
    private contactListService: ContactListService,
    private toastr: ToastrService,
  ) {
    this.stagiaires$ = pagingService.rowsObs;
    this.total$ = pagingService.totalObs;
    this.loading$ = pagingService.loadingObs;
    this.pagingService.searchTerm = '';
    // set fields for filtering
    this.pagingService.fields = this.searchFields;
  }

  ngOnInit() {

    // load MLP campaign
    if (this.formation) {

      this.pagingService.loadRows(of([]));

      this.pagingService.loadRows(this.adeService.GetAdeStagiaires(this.formation.id));
      this.loading$.subscribe(loading => {
        if (loading) {
          this.spinner.show();
        } else {
          this.spinner.hide();
          this.initialized = true;
        }
      });
      this.loadContactList();
      this.pagingService.page = 1;

    }
  }

  pageChanged(event) {
    console.log('onPageChange', event);
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.pagingService.sortColumn = column;
    this.pagingService.sortDirection = direction;
  }

  Export(kind) {
    console.log('export', kind);
    const records = [];
    const fields = ['id', 'nom', 'prenom', 'mobile', 'reponse', 'commentaires', 'date_reponse', 'date_last_sms'];
    for (const u of this.pagingService.filteredRows) {
      const user = R.pick(fields, u);
      records.push(user);
    }
    const header = {};
    fields.forEach(f => header[f] = f.replace(/[^a-z0-9]/gmi, '').toUpperCase());
    records.unshift(header);
    this.exportDataService.exportAs(kind, records, 'credits_' + moment().format('YYYY-MM-DD'));
  }

  loadContactList() {
    this.contactListService.GetList(this.client.id, this.formation.listid)
      .subscribe((data: any) => {
        this.list = data;
      });
  }

  createCampaign(contactid) {
    const now = new Date();
    const message = 'Bonjour %PRENOM STAGIAIRE% %NOM  STAGIAIRE%,\nmerci de confirmer votre participation au stage %STAGE% Alliance le %DATES FORMATION% à %DEPOT OU SE DEROULE LE STAGE%.\nValidez sur https://isisms.fr/%SURL%';
    const campaign = {
      id: 0,
      customerid: 0,
      name: 'Stage ' + this.formation.id,
      message,
      messagelen: message.length,
      fromname: 'ALLIANCE',
      description: '',
      datestart: moment().format('YYYY-MM-DD') + ' 08:00',
      datestop: moment().format('YYYY-MM-DD') + ' 18:00',
      startdate: now.toISOString(),
      stopdate: now.toISOString(),
      starttime: now.toISOString(),
      stoptime: now.toISOString(),
      statut: '0',
      total: 0,
      progress: '0',
      sendretry: '0',
      priority: { value: '1', label: 'Premium', visible: '1' },
      endreceipt: this.client.username,
      cost: '0',
      datecreation: '',
      datemodification: '',
      fields: this.list.fields,
      asap: '1',
      destination_list: '',
      seq_type: 0,
      seq_nb_per_period: '',
      seq_delay: '0',
      seq_duration: '0h 0m',
      seq_minutes: '0',
      total_contact: 1,
      hasURL: true,
      seq_hduration: '0.00',
      seq_available: 0,
      weekday: [],
      timerange: [],
    };

    this.campaignService.Add(this.client.id, campaign).subscribe((response: any) => {
      if (response.success) {
        this.toastr.success('La campagne a été créée');
        campaign.id = response.id;

        this.adeService.SetCampaignID(this.client.id, this.formation.id, campaign.id, contactid)
          // tslint:disable-next-line: no-shadowed-variable
          .subscribe((response: any) => {
            if (!response.success) {
              this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
            } else {

              const listids = [this.list.id];
              this.campaignService.AddList(this.client.id, campaign.id, listids)
                // tslint:disable-next-line: no-shadowed-variable
                .subscribe((response: any) => {
                  if (response.success) {
                    // this.toastr.success('La liste de contact a été ajoutée');
                    // autostart campaign
                    if (contactid === '0') {
                      this.campaignService.Start(this.client.id, campaign.id)
                        // tslint:disable-next-line: no-shadowed-variable
                        .subscribe((response: any) => {
                          if (response.success) {
                            this.toastr.success('La campagne est validée. Elle démarrera automatiquement');
                          } else {
                            this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
                          }
                        });
                    } else {
                      // tslint:disable-next-line: no-shadowed-variable
                      this.campaignService.StartForContact(this.client.id, campaign.id, contactid).subscribe((response: any) => {
                        if (response.success) {
                          this.toastr.success('La campagne est validée. Elle démarrera automatiquement');
                        } else {
                          this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
                        }
                      });
                    }
                  }
                });
            }
          });
      } else {
        this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
      }
    });
  }



  addStagiaire() {
    const fields = JSON.parse(this.list.fields);

    this.testVars = {
      mobile: '',
      fields,
      mandatory: Array(fields.length),
      values: Array(fields.length).join('.').split('.')   // init an empty array
    };

    const mandatory = ['NOM\u00a0 STAGIAIRE', 'NOM STAGIAIRE', 'NOM  STAGIAIRE', 'PRENOM STAGIAIRE', 'N° TELEPHONE', 'MAIL'];
    for (let i = 0; i < this.testVars.fields.length; i++) {
      console.log(this.testVars.fields[i]);
      this.testVars.mandatory[i] = mandatory.includes(this.testVars.fields[i]);
      console.log(this.testVars.mandatory[i]);
    }
    this.showAddStagiaire = true;
  }

  addStagiaireConfirm() {
    // copier tous les champs non mandatory
    // NOM DU STAGE, DATES FORMATION, DATES COMPLETES
    /*
    0 N° PARTICIPANT
    2 DEPOT OU SE DEROULE LE STAGE
    9 STAGE
    10 NOM DU STAGE
    11 DATES FORMATION
    12 DATES COMPLETES
    19 PRENOM STAGIAIRE
    20 NOM  STAGIAIRE
    24 N° TELEPHONE
    25 MAIL
    */
    const infos = this.testVars.values;
    infos[9] = this.formation.stage;
    infos[10] = this.formation.name.replace(/"/g, '');
    infos[11] = moment(this.formation.date_debut).format('DD/MM/YYYY');
    infos[12] = this.formation.dates;
    infos[2] = this.formation.ville;

    this.contactListService.AddContactToList(
      this.client.id,
      this.list.id,
      JSON.stringify(infos))
      .subscribe((response: any) => {
        if (response.success) {
          this.toastr.success('Le contact a été créé');
          // now add to stagiaire list
          const stagiaire = { contactid: response.contactid, nom: infos[20], prenom: infos[19], mobile: infos[24], email: infos[25], stageid: this.formation.id };
          this.adeService.SaveAdeStagiaire(this.formation.id, stagiaire).subscribe((data: any) => {
            console.log('stagiaire ', data);

            // reload selected stage to update stagiaire list
            this.toastr.success('Le stagiaire est ajouté');
            this.formation.nbparticipants++;
            this.pagingService.loadRows(this.adeService.GetAdeStagiaires(this.formation.id));

            this.createCampaign(stagiaire.contactid);
          });
        } else {
          this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
        }
      });
    this.showAddStagiaire = false;
  }

  edit() {
    this.editFormation.emit(this.formation);
  }
}
