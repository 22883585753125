import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as Excel from 'exceljs/dist/exceljs.min.js';
import { ClipboardService } from 'ngx-clipboard';
import { NgxSpinnerService } from 'ngx-spinner';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

const TEXT_TYPE = 'application/txt';
const TEXT_EXTENSION = '.csv';


@Injectable({
  providedIn: 'root'
})
export class ExportDataService {


  constructor(
    private clipboardService: ClipboardService,
    private spinner: NgxSpinnerService,
  ) { }

  public exportAs(kind: string, json: any[], excelFileName: string = null, images?: any) {
    if (kind === 'CSV') {
      this.exportAsCsvFile(json, excelFileName);
    } else if (kind === 'EXCEL') {
      if (!images) {
        this.exportAsExcelFile(json, excelFileName, images);
      } else {
        this.exportAsExcelFile2(excelFileName, json[0], json.slice(1), images)
      }
    } else if (kind === 'PRINT') {
      this.exportAsHtml(json);
    } else if (kind === 'TEXT') {
      this.exportAsText(json);
    }
  }


  public exportAsExcelFile(json: any[], excelFileName: string, images?: any): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, { skipHeader: true });
    // console.log('worksheet', worksheet);

    const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public exportAsExcelFile2(title, header, data, images): void {


    try {

    //Create a workbook with a worksheet
    let workbook = new Excel.Workbook();
    let worksheet = workbook.addWorksheet(title);

    // THIS IS MLP specific !!!
    worksheet.getColumn(2).width = 15;
    worksheet.getColumn(3).width = 25;
    worksheet.getColumn(4).width = 25;
    worksheet.getColumn(5).width = 10;
    worksheet.getColumn(7).width = 25;


    //Adding Header Row
    const headerRow = worksheet.addRow(Object.values(header));

    //Blank Row
    worksheet.addRow([]);


    // Adding Data with Conditional Formatting
    let row = 0;
    data.forEach(d => {
      // const newrow = worksheet.addRow(Object.values(d));
      // for(let loop=0; loop<10; loop++) worksheet.addRow([]);
      const values = Object.values(d);
      for(let col=0; col<values.length; col++) {
        // worksheet.mergeCells(`${String.fromCharCode(65 + col)}${row*10+3}`, `${String.fromCharCode(65 + col)}${row*10+10}`);
        worksheet.getCell(`${String.fromCharCode(65 + col)}${row*10 + 6}`).value = values[col];
      }

      if (images[row] && images[row][0].image) {
        //Add Image
        let photo1 = workbook.addImage({
          base64: images[row][0].image,
          extension: 'png',
        });
        worksheet.mergeCells(`K${row*10+3}`, `L${row*10+10}`);
        worksheet.addImage(photo1, `K${row*10+3}:L${row*10+10}`);
        worksheet.getCell(`M${row*10+6}`).value = {
          text: 'photo1',
          hyperlink: images[row][0].url,
          tooltip: 'photo1'
        };
      }
      if (images[row] && images[row][1].image) {
        //Add Image
        let photo2 = workbook.addImage({
          base64: images[row][1].image,
          extension: 'png',
        });
        worksheet.mergeCells(`N${row*10+3}`, `O${row*10+10}`);
        worksheet.addImage(photo2, `N${row*10+3}:O${row*10+10}`);
        worksheet.getCell(`P${row*10+6}`).value = {
          text: 'photo2',
          hyperlink: images[row][1].url,
          tooltip: 'photo2'
        };
      }
      if (images[row] && images[row][2].image) {
        //Add Image
        let photo3 = workbook.addImage({
          base64: images[row][2].image,
          extension: 'png',
        });
        worksheet.mergeCells(`Q${row*10+3}`, `R${row*10+10}`);
        worksheet.addImage(photo3, `Q${row*10+3}:R${row*10+10}`);
        worksheet.getCell(`S${row*10+6}`).value = {
          text: 'photo3',
          hyperlink: images[row][2].url,
          tooltip: 'photo3'
        };
      }
      row += 1;

    });

    //Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      this.saveAsExcelFile(blob, title + '.xlsx');
    })

    } catch(e) {
      this.spinner.hide();
    }

  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    this.spinner.hide();

  }

  public exportAsCsvFile(json: any[], csvFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, { skipHeader: true });
    const csv = XLSX.utils.sheet_to_csv(worksheet);
    this.saveAsCsvFile(csv, csvFileName);
  }

  private saveAsCsvFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: TEXT_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + TEXT_EXTENSION);
    this.spinner.hide();
  }

  public exportAsHtml(json: any[]): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, { skipHeader: true });
    const printContent = XLSX.utils.sheet_to_html(worksheet);
    // .replace('<table>', '<table table border="1" cellpadding="0" cellspacing="0" style="border-collapse:collapse;">');

    // console.log(printContent);

    const windowUrl = 'about:blank';
    const windowName = 'Print' + new Date().getTime();
    const printWindow = window.open(windowUrl, windowName, 'left=50000,top=50000,width=0,height=0');

    printWindow.document.write(printContent);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
    this.spinner.hide();
  }

  public exportAsText(json: any[]): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, { skipHeader: true });
    const txtContent = XLSX.utils.sheet_to_csv(worksheet, { FS: '\t'});
    this.clipboardService.copyFromContent(txtContent);
    this.spinner.hide();
  }


  // toUTF8Array(str) {
  //   const utf8 = [];
  //   for (let i = 0; i < str.length; i++) {
  //       let charcode = str.charCodeAt(i);
  //       if (charcode < 0x80) {
  //         utf8.push(charcode);
  //       } else if (charcode < 0x800) {
  //         // tslint:disable-next-line:no-bitwise
  //         utf8.push(0xc0 | (charcode >> 6), 0x80 | (charcode & 0x3f));
  //       } else if (charcode < 0xd800 || charcode >= 0xe000) {
  //         // tslint:disable-next-line:no-bitwise
  //         utf8.push(0xe0 | (charcode >> 12), 0x80 | ((charcode>>6) & 0x3f), 0x80 | (charcode & 0x3f));
  //       } else {
  //         // surrogate pair
  //           i++;
  //           // UTF-16 encodes 0x10000-0x10FFFF by
  //           // subtracting 0x10000 and splitting the
  //           // 20 bits of 0x0-0xFFFFF into two halves
  //           // tslint:disable-next-line:no-bitwise
  //           charcode = 0x10000 + (((charcode & 0x3ff) << 10) | (str.charCodeAt(i) & 0x3ff));
  //           // tslint:disable-next-line:no-bitwise
  //           utf8.push(0xf0 | (charcode >> 18), 0x80 | ((charcode >> 12) & 0x3f), 0x80 | ((charcode >> 6) & 0x3f), 0x80 | (charcode & 0x3f));
  //       }
  //   }
  //   return utf8.toString();
  // }
}
