
<div class="p-20">

  <form>
    <div class="row">
      <div class="form-group col-4">
        <label for="startdate">Date de début de période</label>
        <div class="input-group">
          <input class="form-control" placeholder="yyyy-mm-dd" name="startdate" [(ngModel)]="period.startdate"
            ngbDatepicker #d1="ngbDatepicker" (dateSelect)="checkDate()" (click)="d1.toggle()">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary btn-grey" (click)="d1.toggle()" type="button"><i
                class="fa fa-calendar"></i></button>
          </div>
        </div>
      </div>
      <div class="form-group col-4">
        <label for="stopdate">Date de fin</label>
        <div class="input-group">
          <input class="form-control" placeholder="yyyy-mm-dd" name="stopdate" [(ngModel)]="period.stopdate"
            ngbDatepicker #d2="ngbDatepicker" (dateSelect)="checkDate()" (click)="d2.toggle()">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary btn-grey" (click)="d2.toggle()" type="button"><i
                class="fa fa-calendar"></i></button>
          </div>
        </div>
      </div>
      <div class="form-group col-4">
        <button class="btn" style="margin-top: 32px;"
          [ngClass]="{'btn-success blink':dateChanged, 'btn-info': !dateChanged}"
          (click)="selectPeriod()">Rechercher</button>
      </div>
    </div>

  </form>
  <br>
  <br>

    <div class="clearfix">
      <form class="inline">
        <button type="button" (click)="Export('TEXT')" class="btn btn-sm btn-outline-secondary ml-2 h-100">Copy</button>
        <button type="button" (click)="Export('CSV')" class="btn btn-sm btn-outline-secondary ml-2 h-100">CSV</button>
        <button type="button" (click)="Export('EXCEL')" class="btn btn-sm btn-outline-secondary ml-2 h-100">Excel</button>
        <button type="button" (click)="Export('PRINT')" class="btn btn-sm btn-outline-secondary ml-2 h-100">Print</button>

        <div class="form-group form-inline ml-auto">
          Recherche: <input class="form-control ml-2" type="text" name="searchTerm"
            [(ngModel)]="pagingService.searchTerm" />
        </div>
      </form>

      <table id="selectedColumn" class="table table-bordered table-striped table-hover table-sm" cellspacing="0"
        width="100%">
        <thead>
          <tr>
            <th class="th" sortable="id" (sort)="onSort($event)">ID</th>
            <th class="th" sortable="admin" (sort)="onSort($event)">Admin</th>
            <th class="th" sortable="company" (sort)="onSort($event)">Société</th>
            <th class="th" sortable="entity" (sort)="onSort($event)">Entity</th>
            <th class="th" sortable="client" (sort)="onSort($event)">Client</th>
            <th class="th" sortable="premium" (sort)="onSort($event)">Conso</th>
          </tr>
        </thead>
        <tbody *ngIf="initialized">
          <tr *ngFor="let u of users$ | async"  [ngClass]="{'selected': u.id === selectedu?.id}">
            <td>{{u.id}}</td>
            <td>
              <ngb-highlight [result]="u.admin" [term]="pagingService.searchTerm">
              </ngb-highlight>
            </td>
            <td class="collapsible">
              <ngb-highlight [result]="u.company" [term]="pagingService.searchTerm">
              </ngb-highlight>
            </td>
            <td class="collapsible">
              <ngb-highlight [result]="u.entity" [term]="pagingService.searchTerm">
              </ngb-highlight>
            </td>
            <td class="collapsible">
              {{u.username}}<br/>{{u.name}}
            </td>
            <td>{{u.premium}}</td>
          </tr>
        </tbody>
      </table>

      <div class="inline">
        <div class="">
          Total: {{(total$ | async)}}
        </div>
        <div class="ml-auto">
          <ngb-pagination class="d-flex justify-content-end" [collectionSize]="total$ | async"
            [(page)]="pagingService.page" [boundaryLinks]="true" [rotate]="true" [directionLinks]="true"
            [pageSize]="pagingService.pageSize" [maxSize]="3" name="page">
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>

