<div class="main-container">

  <div class="header">
    <a class="navbar-brand" href="#/">
      <img [src]="siteicon" />
    </a>
    <!-- <h3 style="margin-top: -5px; font-size: 20px">{{siteurl}}</h3> -->
  </div>

  <div class="" style="margin-top:15%">
    <div id="loginbox" class="mainbox col-sm-8 offset-sm-2 col-xs-12  admin-block p-2">

      <div class="card card-block p-4">
        <div class="card-title">
          <h2>Bienvenue admin!</h2>
        </div>

        <div class="card-block">

          <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <div class="col">
              <div class="form-group">
                <label for="username">Email</label>
                <input type="text" formControlName="username" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                  <div *ngIf="f.username.errors.required">Champ obligatoire</div>
                </div>
              </div>
              <div class="form-group">
                <label for="password">Mot de passe</label>
                <input type="password" formControlName="password" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                  <div *ngIf="f.password.errors.required">Champ obligatoire</div>
                </div>
              </div>
            </div>

            <div class="alert alert-danger" *ngIf="error=='unknown_admin'">Ce compte n'existe pas</div>
            <div class="alert alert-danger" *ngIf="error=='incorrect_password'">Erreur d'authentification</div>

            <div style="clear:both;"></div>
            <div class="form-actions">
              <button type="submit" class="btn btn-success">
                Se connecter
              </button>
              <div class="btn btn-link" (click)="passwordRequest()">Mot de passe oublié</div>
            </div>
          </form>

        </div>
      </div>
    </div>
  </div>

  <div class="hiddenbr" [routerLink]="['/login']">&nbsp;</div>

</div>
