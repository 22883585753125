import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AnnonceService {

  constructor(
    private httpClient: HttpClient,
  ) {}

  GetAll(clientid: number, agenceid: number): Observable<any> {
    const url = environment.apiUrl + '/annonces/getall/' + clientid + '/' + agenceid;
    return this.httpClient.get(url);
  }

  Add(codeAgence, annonce): Observable<any> {
    const url = environment.apiUrl + '/annonces/add/' + codeAgence;
    return this.httpClient.post(url, annonce);
  }

  Update(codeAgence, annonce): Observable<any> {
    const url = environment.apiUrl + '/annonces/' + codeAgence + '/' + annonce.id;
    return this.httpClient.put(url, annonce);
  }

  Delete(codeAgence, annonceid): Observable<any> {
    const url = environment.apiUrl + '/annonces/' + codeAgence + '/' + annonceid;
    return this.httpClient.delete(url);
  }

}

