<ngx-spinner bdOpacity=0.2 bdColor="rgba(51,51,51,0)" size="medium" color="#515151" type="ball-pulse-sync" [fullScreen]="false">
</ngx-spinner>

<div class="card">
  <div class="card-body">

    <form class="form-inline">
      <div class="form-group">
        <button class="btn btn-success" (click)="Add()">
          <i class="fa fa-plus"></i>&nbsp;Ajouter une agence</button>
      </div>
    </form>
    <br>


    <div class="clearfix">
      <form class="inline">
        <div class="form-group form-inline ml-auto">
          Recherche: <input class="form-control ml-2" type="text" name="searchTerm"
            [(ngModel)]="pagingService.searchTerm" />
        </div>
      </form>

      <table id="selectedColumn" class="table table-bordered table-striped table-hover table-sm" cellspacing="0"
        width="100%">
        <thead>
          <tr>
            <th class="th" sortable="id" (sort)="onSort($event)">ID</th>
            <th class="th collapsible" sortable="name" (sort)="onSort($event)">Nom</th>
            <th class="th collapsible" sortable="code_agence" (sort)="onSort($event)">Code</th>
            <th class="th collapsible" sortable="contact" (sort)="onSort($event)">Contact</th>
            <th class="th collapsible" sortable="mobile" (sort)="onSort($event)">N° mobile</th>
            <th class="th collapsible" sortable="smstext" (sort)="onSort($event)">Texte</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody *ngIf="initialized">
          <tr *ngFor="let agence of agences$ | async" (click)="selectAgence(agence)"
            [ngClass]="{'selected': agence.id === selectedAgence?.id}">
            <td>{{agence.id}}</td>
            <td>
              <ngb-highlight [result]="agence.name" [term]="pagingService.searchTerm"></ngb-highlight>
            </td>
            <td>
              <ngb-highlight [result]="agence.code_agence" [term]="pagingService.searchTerm"></ngb-highlight>
            </td>
            <td class="collapsible">
              <ngb-highlight [result]="agence.contact" [term]="pagingService.searchTerm">
              </ngb-highlight>
            </td>
            <td class="collapsible">
              <ngb-highlight [result]="agence.mobile" [term]="pagingService.searchTerm">
              </ngb-highlight>
            </td>
            <td class="collapsible">
              <ngb-highlight [result]="agence.smstext" [term]="pagingService.searchTerm">
              </ngb-highlight>
            </td>
            <td>
              <div class="inline">
                <button type="button" title="Editer" (click)="Edit(agence)" class="btn btn-sm btn-primary ml-1 w30"><i
                    class="fa fa-edit"></i></button>
                <button type="button" title="Supprimer" (click)="Delete(agence)"
                  class="btn btn-sm btn-danger ml-1 w30"><i class="fa fa-trash-o"></i></button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="inline">
        <div class="">
          Total: {{(total$ | async)}}
        </div>
        <div class="ml-auto">
          <ngb-pagination class="d-flex justify-content-end" [collectionSize]="total$ | async"
            [(page)]="pagingService.page" [boundaryLinks]="true" [rotate]="true" [directionLinks]="true"
            [pageSize]="pagingService.pageSize" [maxSize]="3" name="page">
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>


<app-modal [(isActive)]="showDeleteConfirm" [overlayDismiss]="true" (overlayClick)="showDeleteConfirm=false">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Suppression d'une agence</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="showDeleteConfirm=false">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>Confirmez-vous la suppression de cette agence ?</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)='DeleteConfirm()'>Confirmer</button>
      <button type="button" class="btn btn-secondary" data-dismiss="modal"
        (click)="showDeleteConfirm=false">Annuler</button>
    </div>
  </div>
</app-modal>


<app-modal [(isActive)]="showEditAgence" [overlayDismiss]="false" [isLarge]="true">
  <div class="modal-dialog modal-dialog-scrollable">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">{{selectedAgence.id == 0 ? 'Ajout' : 'Edition'}} agence</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="showEditAgence=false">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form role="form" name="form">

        <div class="form-group">
          <label for="code">Code (20 carac. max.)</label>
          <input type="text" name="code" class="form-control" [(ngModel)]="selectedAgence.code_agence"
            autocomplete="off" autocapitalize="off" autocorrect="off" #labelRef="ngModel"
            [ngClass]="{ 'is-invalid': labelRef.invalid }" required />
          <span *ngIf="labelRef.dirty && labelRef.errors?.required" class="text-danger"> Saisissez un code</span>
        </div>

        <div class="form-group">
          <label for="contact">Nom</label>
          <input type="text" name="name" class="form-control" [(ngModel)]="selectedAgence.name"
          autocomplete="off" autocapitalize="off" autocorrect="off"/>
        </div>

        <div class="form-group">
          <label for="contact">Email du contact</label>
          <input type="text" name="contact" class="form-control" [(ngModel)]="selectedAgence.contact"
            autocomplete="off" autocapitalize="off" autocorrect="off"/>
        </div>

        <div class="form-group">
          <label for="contact">N° mobile</label>
          <input type="mobile" name="mobile" class="form-control" [(ngModel)]="selectedAgence.mobile"
            autocomplete="off" autocapitalize="off" autocorrect="off"/>
        </div>

        <div class="form-group">
          <label for="smstext">Modéle du texte SMS envoyé</label>
          <ul>
            <li>Ajouter %reference% pour ajouter la référence de l'annonce</li>
            <li>Ajouter %url% pour le lien long de l'annonce</li>
          </ul>
          <textarea name="smstext" id="smstext" class="form-control" style="resize:vertical;margin-bottom:10px;" rows="5" [(ngModel)]="selectedAgence.smstext"></textarea>
        </div>
      </form>

    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)='UpdateAgence()'>{{ selectedAgence.id == 0 ? 'Ajouter' : 'Modifier'}}</button>
      <button type="button" class="btn btn-secondary" data-dismiss="modal"
        (click)="showEditAgence=false">Annuler</button>
    </div>
  </div>
  </div>
</app-modal>
