import { Component, OnInit, Output, EventEmitter, Input, QueryList, ViewChildren } from '@angular/core';
import { IUser } from 'src/app/interfaces/user';
import { PagingService } from 'src/app/providers/paging.service';
import { IModele } from 'src/app/interfaces/modele';
import { ExportDataService } from 'src/app/providers/export-data.service';
import { UserService } from 'src/app/providers/user.service';
import { MlpService } from 'src/app/providers/mlp.service';
import { AuthenticationService } from 'src/app/providers/authentication.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, of } from 'rxjs';
import { NgbdSortableHeader, SortEvent } from 'src/app/directives/sortable.directive';

import moment from 'moment';
import * as R from 'ramda';
import { IMlpCampaign } from 'src/app/interfaces/mlp';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'app-mlp-client',
  templateUrl: './mlp-client.component.html',
  styleUrls: ['./mlp-client.component.scss']
})
export class MlpClientComponent implements OnInit {

  @Input() diffuseur = null;
  @Input() commerciaux = [];
  @Input() chefsRegion = [];
  @Input() customers = [];

  @Input() client: IUser = null;
  // @Input() distri = null;
  @Output() openPhoto: EventEmitter<any> = new EventEmitter<any>();

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  page = 1;
  pageSize = 10;
  collectionSize = 1;
  searchFields = ['id', 'name', 'datecreation'];

  campaigns$: Observable<IMlpCampaign[]>;
  total$: Observable<number>;
  loading$: Observable<boolean>;
  initialized = false;

  selectedNim = undefined;
  chefRegion = '';
  commercial = '';

  diffSearch = undefined;

  constructor(
    public pagingService: PagingService<IMlpCampaign>,
    private exportDataService: ExportDataService,
    private userService: UserService,
    private mlpService: MlpService,
    private authService: AuthenticationService,
    private spinner: NgxSpinnerService,
  ) {

    console.log('distri=', this.diffuseur);

    this.selectedNim = R.clone(this.diffuseur);

    this.campaigns$ = pagingService.rowsObs;
    this.total$ = pagingService.totalObs;
    this.loading$ = pagingService.loadingObs;
    this.pagingService.searchTerm = '';
    // set fields for filtering
    this.pagingService.fields = this.searchFields;
  }

  ngOnInit() {
    if (this.diffuseur) {
      this.loadCampaigns();
    }
    this.loading$.subscribe(loading => {
      if (loading) {
        this.spinner.show();
      } else {
        this.spinner.hide();
      }
    });
    this.pagingService.page = 1;
  }

  formatterDiff = (x: any) => x.diffuseur;

  searchDiff = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 ? []
        : this.customers.filter(v => v.nim.indexOf(term) > -1 || v.diffuseur.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 15))
  )

  selectDiff(value) {
    console.log(value.item);
    this.searchDiff = value.item.diffuseur;

    // select customer
    this.diffuseur = this.customers.find( c => c.id === value.item.id);
    if (this.diffuseur) {
      this.loadCampaigns();
      this.chefRegion = this.getChefRegion(this.diffuseur.chef_region_id);
      this.commercial = this.getCommercial(this.diffuseur.commercial_id);
    }
  }

  loadCampaigns() {
    this.pagingService.loadRows(of([]));
    this.pagingService.loadRows(this.mlpService.GetMlpNim(
      this.diffuseur.nim,
      this.diffuseur.telephone.replace('+', '')
    ));
    this.initialized = true;
    this.pagingService.page = 1;
  }

  pageChanged(event) {
    console.log('onPageChange', event);
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.pagingService.sortColumn = column;
    this.pagingService.sortDirection = direction;
  }

  Export(kind) {
    console.log('export', kind);
    const records = [];
    const fields = ['id', 'name', 'datecreation', 'nbclients', 'nbphotos', 'nbsms', 'totalphotos'];


    for (const u of this.pagingService.filteredRows) {
      const user = R.pick(fields, u);
      records.push(user);
    }
    const header = {};
    fields.forEach(f => header[f] = f.replace(/[^a-z0-9]/gmi, '').toUpperCase());
    records.unshift(header);
    this.exportDataService.exportAs(kind, records, 'credits_' + moment().format('YYYY-MM-DD') );
  }

  showPhoto(c) {
    this.openPhoto.emit(c);
  }

  getChefRegion(cdrid) {
    return this.mlpService.getNameFromId(this.chefsRegion, cdrid);
  }

  getCommercial(commid) {
    return this.mlpService.getNameFromId(this.commerciaux, commid);
  }


}
