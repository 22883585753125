import { Component, OnInit, ViewChildren, QueryList, ViewChild, ElementRef, AfterViewChecked, AfterViewInit, OnDestroy } from '@angular/core';
import { Observable, ReplaySubject, of } from 'rxjs';
import { SortEvent, NgbdSortableHeader } from 'src/app/directives/sortable.directive';
import { environment } from '../../../environments/environment';

import { PagingService } from '../../providers/paging.service';
import { UserService } from 'src/app/providers/user.service';
import { Router, ActivatedRoute } from '@angular/router';

import * as R from 'ramda';
import { ExportDataService } from 'src/app/providers/export-data.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IUser } from 'src/app/interfaces/user';
import { ContactListService } from 'src/app/providers/contact-list.service';
import { Contact, IContact } from 'src/app/interfaces/contact-list';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit, OnDestroy {

  contacts$: Observable<IContact[]>;
  total$: Observable<number>;
  loading$: Observable<boolean>;
  selectedContact: IContact;

  page = 1;
  pageSize = 10;
  collectionSize = 1;
  searchFields = ['id', 'json_data'];

  showAddContact = false;
  fields = [];
  initialized = false;

  client: IUser;
  userSubscribe = null;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(
    public pagingService: PagingService<IContact>,
    private userService: UserService,
    private exportDataService: ExportDataService,
    public contactListService: ContactListService,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router,
    private modalService: NgbModal,
  ) {
    this.contacts$ = pagingService.rowsObs;
    this.total$ = pagingService.totalObs;
    this.loading$ = pagingService.loadingObs;
    this.pagingService.searchTerm = '';
    // set fields for filtering
    this.pagingService.fields = this.searchFields;
  }

  ngOnInit(): void {
    console.log('contact onInit', this.client);
    const path = this.activatedRoute.snapshot.routeConfig.path;

    if (path === 'contact/ajouter') {
      setTimeout(() => {
        this.ShowInsertContact();
      }, 500);
    }

    this.pagingService.loadRows(of([]));

    this.userSubscribe = this.userService.selectedUser$.subscribe(user => {
      this.client = user;
      if (this.client) {
        if (this.contactListService.selectedCList) {

          this.pagingService.loadRows(this.contactListService.ContactGetAll(this.client.id, this.contactListService.selectedCList.id));
          this.loading$.subscribe(loading => {
            if (loading) {
              this.spinner.show();
            } else {
              this.spinner.hide();
              this.initialized = true;
            }
          });
          this.pagingService.page = 1;
        } else {
          this.router.navigate(['/contact/list']);
        }
      } else {
        this.router.navigate(['/']);
      }
    });



  }

  ngOnDestroy() {
    if (this.userSubscribe) {
      this.userSubscribe.unsubscribe();
    }
  }

  back() {
    this.router.navigate(['/contact/list']);
  }

  pageChanged(event) {
    console.log('onPageChange', event);
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.pagingService.sortColumn = column;
    this.pagingService.sortDirection = direction;
  }

  selectContact(contact) {
    this.selectedContact = R.clone(contact);
  }

  setContact(contact) {
    this.selectedContact = R.clone(contact);
    this.contactListService.ContactSelect(
      this.client.id,
      this.contactListService.selectedCList.id,
      contact.id, 1).subscribe((result: any) => {
        if (result.success) {
          contact.selected = 1;
          this.pagingService.loadRows(this.contactListService.ContactGetAll(this.client.id, this.contactListService.selectedCList.id));
        } else {
          this.toastr.error(result.message, 'Erreur', { timeOut: 0 });
        }
      });
  }

  clearContact(contact) {
    this.selectedContact = R.clone(contact);
    this.contactListService.ContactSelect(
      this.client.id,
      this.contactListService.selectedCList.id,
      contact.id, 0).subscribe((result: any) => {
        if (result.success) {
          contact.selected = 0;
          this.pagingService.loadRows(this.contactListService.ContactGetAll(this.client.id, this.contactListService.selectedCList.id));
        } else {
          this.toastr.error(result.message, 'Erreur', { timeOut: 0 });
        }
      });
  }

  Export(kind) {
    console.log('export', kind);
    const records = [];
    const fields = ['id', 'listid', 'json_data', 'selected'];
    const fdata = JSON.parse(this.contactListService.selectedCList.fields);
    for (const c of this.pagingService.filteredRows) {
      const data = JSON.parse(c.json_data);
      const model = { SELECTED: 0 };
      for (let index = 0; index < fdata.length; index++) {
        model[fdata[index]] = data[index];
      }
      //model.SELECTED = c.selected;
      records.push(model);
    }
    // const header = { SELECTED: 'SELECTED' };
    const header = { };
    for (const f of fdata) {
      header[f] = f;
    }
    records.unshift(header);
    this.exportDataService.exportAs(kind, records, 'contact');
  }

  setAllContact(value) {
    this.spinner.show();
    this.contactListService.ContactSelect(
      this.client.id,
      this.contactListService.selectedCList.id,
      -1, value).subscribe((result: any) => {
        if (result.success) {
          this.pagingService.loadRows(this.contactListService.ContactGetAll(this.client.id, this.contactListService.selectedCList.id));
        } else {
          this.toastr.error(result.message, 'Erreur', { timeOut: 0 });
        }
        this.spinner.hide();
      });
  }

  ShowInsertContact() {
    if (!this.contactListService.selectedCList) {
      return;
    }
    this.showAddContact = true;
    this.fields = [];
    for (const f of JSON.parse(this.contactListService.selectedCList.fields)) {
      this.fields.push({ label: f, value: '' });
    }
  }

  InsertContact() {
    this.spinner.show();
    this.contactListService.AddContactToList(
      this.client.id,
      this.contactListService.selectedCList.id,
      JSON.stringify(this.fields.map(f => f.value))
    ).subscribe((response: any) => {

      if (response.success) {
        this.toastr.success('Le contact a été créé', '');
        this.pagingService.loadRows(this.contactListService.ContactGetAll(this.client.id, this.contactListService.selectedCList.id));
      } else {
        this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
      }
      this.spinner.hide();
    });
    this.showAddContact = false;
  }
}
