<div *ngIf="!diffuseur">
  <div class="alert alert-danger w-100">Séléctionnez en premier un diffuseur dans la liste 'Stat
    campagne' ou sélectionnez un diffuseur.</div>
</div>

<br>
<div class="p-20">

  <div class="row">
    <div class="col-3 mt-2">
      Sélectionner un diffuseur:
    </div>
    <div class="col-5">
      <ng-template #rt let-r="result" let-t="term">
        <div class="col3">
          <ngb-highlight [result]="r.nim" [term]="t"></ngb-highlight>
          <ngb-highlight [result]="r.diffuseur" [term]="t"></ngb-highlight>
          <ngb-highlight [result]="r.telephone" [term]="t"></ngb-highlight>
        </div>
      </ng-template>
      <input id="typeahead-format" type="text" class="form-control" [(ngModel)]="diffSearch" [ngbTypeahead]="searchDiff"
        (selectItem)="selectDiff($event)" [resultTemplate]="rt" [inputFormatter]="formatterDiff" />
    </div>
  </div>

  <br>
  <br>

  <div class="alert alert-well client-info">
    <h4>{{diffuseur?.civilite}} {{diffuseur?.diffuseur}}</h4>
    <div class="row">
      <div class="col-sm-6">NIM:&nbsp;{{diffuseur?.nim}}</div>
      <div class="col-sm-6">Téléphone:&nbsp;{{diffuseur?.telephone}}</div>
    </div>
    <div class="row">
      <div class="col-sm-6">Chef:&nbsp; {{chefRegion}}</div>
      <div class="col-sm-6">Commercial:&nbsp; {{commercial}}</div>
    </div>
    <div class="row">
      <div class="col-sm-6">N°Dépot:&nbsp; {{diffuseur?.num_depot}}</div>
      <div class="col-sm-6">NIL:&nbsp; {{diffuseur?.nil}}</div>
    </div>
    <br>
    <div class="row">
      <div class="col-sm-6">{{diffuseur?.cpostal}}</div>
      <div class="col-sm-6">{{diffuseur?.ville}}</div>
    </div>
  </div>

</div>
<br>

<div class="clearfix">
  <form class="inline">
    <button type="button" (click)="Export('TEXT')" class="btn btn-sm btn-outline-secondary ml-2 h-100">Copy</button>
    <button type="button" (click)="Export('CSV')" class="btn btn-sm btn-outline-secondary ml-2 h-100">CSV</button>
    <button type="button" (click)="Export('EXCEL')" class="btn btn-sm btn-outline-secondary ml-2 h-100">Excel</button>
    <button type="button" (click)="Export('PRINT')" class="btn btn-sm btn-outline-secondary ml-2 h-100">Print</button>

    <div class="form-group form-inline ml-auto">
      Recherche: <input class="form-control ml-2" type="text" name="searchTerm"
        [(ngModel)]="pagingService.searchTerm" />
    </div>
  </form>

  <table id="selectedColumn" class="table table-bordered table-striped table-hover table-sm" cellspacing="0"
    width="100%">
    <thead>
      <tr>
        <th class="th" sortable="id" (sort)="onSort($event)">ID</th>
        <th class="th" sortable="name" (sort)="onSort($event)">Campagne</th>
        <th class="th collapsible" sortable="datecreation" (sort)="onSort($event)">Date</th>
        <th class="th collapsible" sortable="smsok" (sort)="onSort($event)">#smsok</th>
        <th class="th collapsible" sortable="photo1" (sort)="onSort($event)">Photos</th>
        <th class="th"></th>
      </tr>
    </thead>
    <tbody *ngIf="initialized">
      <tr *ngFor="let c of campaigns$ | async">
        <td>{{c.id}}</td>
        <td>
          <ngb-highlight [result]="c.name" [term]="pagingService.searchTerm"></ngb-highlight>
        </td>
        <td class="collapsible">
          <ngb-highlight [result]="c.datecreation" [term]="pagingService.searchTerm">
          </ngb-highlight>
        </td>
        <td class="collapsible">
          <ngb-highlight [result]="c.smsok" [term]="pagingService.searchTerm">
          </ngb-highlight>
        </td>
        <td class="collapsible">
          {{( c.photo1 !== '' ? "1" : "&nbsp;" )+
          ( c.photo2 !== '' && c.nb_photos>=2 ? "-2" : "-&nbsp;" )+
          ( c.photo3 !== '' && c.nb_photos>=3 ? "-3" : "" )}}
        </td>
        <td>
          <div class="inline">
            <button type="button" title="Voir les détails" (click)="showPhoto(c)"
              class="btn btn-sm btn-success mr-2 w30"><i class="fa fa-camera"></i></button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>


  <div class="inline">
    <div class="">
      Total: {{(total$ | async)}}
    </div>
    <div class="ml-auto">
      <ngb-pagination class="d-flex justify-content-end" [collectionSize]="total$ | async" [(page)]="pagingService.page"
        [boundaryLinks]="true" [rotate]="true" [directionLinks]="true" [pageSize]="pagingService.pageSize" [maxSize]="3"
        name="page">
      </ngb-pagination>
    </div>
  </div>
</div>
