<div class="client-view p-md-4">
  <ngx-spinner bdOpacity=0.2 bdColor="rgba(51,51,51,0)" size="medium" color="#515151" type="ball-pulse-sync"
    [fullScreen]="false">
  </ngx-spinner>

  <app-client-select [selectedUser]="client"></app-client-select>



  <div class="card">
    <div class="card-body">

      <h4>Liste des campagnes</h4>

      <ul class="nav nav-pills mb-n3" id="myTab" role="tablist">
        <li class="nav-item">
          <div class="nav-link" [ngClass]="{'active': activeTab===0}" id="tab0" data-toggle="tab" (click)="selectTab(0)"
            role="tab" aria-controls="en préparation" aria-selected="true">En préparation</div>
        </li>
        <li class="nav-item">
          <div class="nav-link" [ngClass]="{'active': activeTab===1}" id="tab1" data-toggle="tab" (click)="selectTab(1)"
            role="tab" aria-controls="en cours" aria-selected="false">En cours</div>
        </li>
        <li class="nav-item">
          <div class="nav-link" [ngClass]="{'active': activeTab===2}" id="tab2" data-toggle="tab" (click)="selectTab(2)"
            role="tab" aria-controls="archivées" aria-selected="false">Archivées</div>
        </li>
      </ul>
      <hr>

      <div class="clearfix">
        <form class="inline">
          <button type="button" (click)="Export('TEXT')"
            class="btn btn-sm btn-outline-secondary ml-2 h-100">Copy</button>
          <button type="button" (click)="Export('CSV')" class="btn btn-sm btn-outline-secondary ml-2 h-100">CSV</button>
          <button type="button" (click)="Export('EXCEL')"
            class="btn btn-sm btn-outline-secondary ml-2 h-100">Excel</button>
          <button type="button" (click)="Export('PRINT')"
            class="btn btn-sm btn-outline-secondary ml-2 h-100">Print</button>

          <div class="form-group form-inline ml-auto">
            Recherche: <input class="form-control ml-2" type="text" name="searchTerm"
              [(ngModel)]="pagingService.searchTerm" />
            <!-- <span class="ml-3" *ngIf="loading$ | async">Chargement...</span> -->
          </div>
        </form>

        <table id="selectedColumn" class="table table-bordered table-striped table-hover table-sm" cellspacing="0"
          width="100%">
          <thead>
            <tr *ngIf="activeTab===0">
              <th class="th" sortable="id" (sort)="onSort($event)">ID</th>
              <th class="th" sortable="name" (sort)="onSort($event)">Nom</th>
              <th class="th collapsible" sortable="type" (sort)="onSort($event)">Type</th>
              <th class="th collapsible" sortable="datemodification" (sort)="onSort($event)">Modifiée</th>
              <th class="th collapsible" sortable="total" (sort)="onSort($event)">#Contacts</th>
              <th class="th collapsible" sortable="messagelen" (sort)="onSort($event)">Longueur</th>
              <th class="th">&nbsp;</th>
            </tr>
            <tr *ngIf="activeTab===1">
              <th class="th" sortable="id" (sort)="onSort($event)">ID</th>
              <th class="th" sortable="name" (sort)="onSort($event)">Nom</th>
              <th class="th collapsible" sortable="type" (sort)="onSort($event)">Type</th>
              <th class="th collapsible" sortable="datebegin" (sort)="onSort($event)">Début envoi</th>
              <th class="th collapsible" sortable="dateend" (sort)="onSort($event)">Fin envoi</th>
              <th class="th collapsible" sortable="priority" (sort)="onSort($event)">Type</th>
              <th class="th collapsible" sortable="total" (sort)="onSort($event)">#Contacts</th>
              <th class="th collapsible" sortable="cost" (sort)="onSort($event)">Crédit SMS</th>
              <th class="th" style="width: 200px">% Progression des accusés de réception</th>
              <th class="th">&nbsp;</th>
            </tr>
            <tr *ngIf="activeTab===2">
              <th class="th" sortable="id" (sort)="onSort($event)">ID</th>
              <th class="th" sortable="name" (sort)="onSort($event)">Nom</th>
              <th class="th collapsible" sortable="type" (sort)="onSort($event)">Type</th>
              <th class="th collapsible" sortable="dateend" (sort)="onSort($event)">Terminée</th>
              <th class="th collapsible" sortable="priority" (sort)="onSort($event)">Type</th>
              <th class="th collapsible" sortable="total" (sort)="onSort($event)">#Contacts</th>
              <th class="th collapsible" sortable="cost" (sort)="onSort($event)">Crédit SMS</th>
              <th class="th" style="width: 200px">% Progression des accusés de réception</th>
              <th class="th">&nbsp;</th>
            </tr>
          </thead>
          <tbody *ngIf="initialized && activeTab===0">
            <tr *ngFor="let c of campaigns$ | async" (click)="selectCampaign(c)"
              [ngClass]="{'selected': c.id === selectedCampaign?.id}">
              <td>{{c.id}}</td>
              <td>
                <ngb-highlight [result]="c.name" [term]="pagingService.searchTerm"></ngb-highlight>
              </td>
              <td class="collapsible">
                <ngb-highlight [result]="c.type" [term]="pagingService.searchTerm">
                </ngb-highlight>
              </td>
              <td class="collapsible">
                <ngb-highlight [result]="c.datemodification" [term]="pagingService.searchTerm">
                </ngb-highlight>
              </td>
              <td class="collapsible">
                <ngb-highlight [result]="c.total" [term]="pagingService.searchTerm">
                </ngb-highlight>
              </td>
              <td class="collapsible">
                <ngb-highlight [result]="c.messagelen" [term]="pagingService.searchTerm">
                </ngb-highlight>
              </td>
              <td>
                <div class="inline">
                  <button type="button" title="Editer" (click)="Edit(c)" class="btn btn-sm btn-primary ml-1 w30"><i
                      class="fa fa-edit"></i></button>
                  <button type="button" title="Test unitaire" (click)="Test(c)" class="btn btn-sm btn-info ml-1 w30"><i
                      class="fa fa-check-square-o"></i></button>
                  <button type="button" title="Activer" (click)="Start(c)" class="btn btn-sm btn-success ml-1 w30"><i
                      class="fa fa-play"></i></button>
                  <button type="button" title="Dupliquer" (click)="Copy(c)" class="btn btn-sm btn-warning ml-1 w30"><i
                      class="fa fa-clone"></i></button>
                  <button type="button" title="Retours" (click)="RcvdMessages(c)"
                    class="btn btn-sm btn-warning ml-1 w30"
                    *ngIf="c.priority>=1 && c.rcvdmessages>0">{{c.rcvdmessages}}</button>
                  <button type="button" title="Supprimer" (click)="Delete(c)" class="btn btn-sm btn-danger ml-1 w30"><i
                      class="fa fa-trash-o"></i></button>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="initialized && activeTab>0">
            <tr *ngFor="let c of campaigns$ | async" (click)="selectCampaign(c)"
              [ngClass]="{'selected': c.id === selectedCampaign?.id}">
              <td>{{c.id}}</td>
              <td>
                <ngb-highlight [result]="c.name" [term]="pagingService.searchTerm"></ngb-highlight>
              </td>
              <td class="collapsible">
                <ngb-highlight [result]="c.type" [term]="pagingService.searchTerm">
                </ngb-highlight>
              </td>
              <td class="collapsible" *ngIf="activeTab===1">
                <ngb-highlight [result]="c.datebegin" [term]="pagingService.searchTerm">
                </ngb-highlight>
              </td>
              <td class="collapsible">
                <ngb-highlight [result]="c.dateend" [term]="pagingService.searchTerm">
                </ngb-highlight>
              </td>
              <td class="collapsible">
                {{ c.priority == '1' ? 'Premium' : ( c.priority == '2' ? 'Unitaire' : 'Lowcost') }}
              </td>
              <td class="collapsible">
                <ngb-highlight [result]="c.total" [term]="pagingService.searchTerm">
                </ngb-highlight>
              </td>
              <td class="collapsible">
                <ngb-highlight [result]="c.cost" [term]="pagingService.searchTerm">
                </ngb-highlight>
              </td>
              <td class="pt-3" style="width: 200px">
                <div *ngIf="c.status==='3'" class="alert alert-sm alert-success">En pause</div>
                <div *ngIf="c.status==='4'" class="alert alert-sm alert-danger">Plus de crédits disponibles</div>
                <div *ngIf="c.status==='5'" class="alert alert-sm alert-danger">Erreur de connexion</div>
                <div *ngIf="c.status==='6'" class="alert alert-sm alert-info">Pas de plage horaire disponible</div>
                <div *ngIf="c.status==='7'" class="alert alert-sm alert-warning">Compte client désactivé</div>

                <div class="progress">
                  <div class="progress-bar bg-success" role="progressbar" [style.width.%]="c.progressD100"
                    aria-valuenow="c.progressD100" aria-valuemin="0" aria-valuemax="100"></div>
                  <div class="progress-bar bg-warning" role="progressbar" [style.width.%]="c.progressW100"
                    aria-valuenow="c.progressW100" aria-valuemin="0" aria-valuemax="100"></div>
                  <div class="progress-bar bg-info" role="progressbar" [style.width.%]="c.progressX100"
                    aria-valuenow="c.progressX100" aria-valuemin="0" aria-valuemax="100"></div>
                  <div class="progress-bar bg-danger" role="progressbar" [style.width.%]="c.progressE100"
                    aria-valuenow="c.progressE100" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div *ngIf="c.asap === 2" class="counter">
                  <div>Envoyés:</div>
                  <div>{{c.progress}} ( {{c.progress100}}% )</div>
                </div>
                <div class="counter">
                  <div>Reçus:</div>
                  <div>{{c.received}} ( {{c.progressD100}}% )</div>
                </div>
                <div class="counter">
                  <div>En attente:</div>
                  <div>{{c.sent}} ( {{c.progressW100}}% )</div>
                </div>
                <div class="counter">
                  <div>Expirés:</div>
                  <div>{{c.expired}} ( {{c.progressX100}}% )</div>
                </div>
                <div class="counter">
                  <div>En erreur:</div>
                  <div>{{c.error}} ( {{c.progressE100}}% )</div>
                </div>
              </td>
              <td *ngIf="activeTab==1">
                <div class="inline">
                  <button type="button" title="Arrêter" (click)="Cancel(c)" class="btn btn-sm btn-danger ml-1 w30"><i
                      class="fa fa-close"></i></button>
                  <button *ngIf="c.priority>=1" type="button" title="Détails" (click)="Details(c)"
                    class="btn btn-sm btn-info ml-1 w30"><i class="fa fa-list-ul"></i></button>
                  <button *ngIf="c.priority>=1" type="button" title="Retours" (click)="RcvdMessages(c)"
                    class="btn btn-sm btn-warning ml-1 w30">{{c.rcvdmessages}}</button>
                  <button *ngIf="c.asap != 2" type="button" title="Archiver" (click)="Archive(c)"
                    class="btn btn-sm btn-primary ml-1 w30"><i class="fa fa-external-link"></i></button>

                  <button *ngIf="c.asap == 2 && c.status>=3" type="button" title="Redémarrer" (click)="Resume(c)"
                    class="btn btn-sm btn-success ml-1 w30"><i class="fa fa-play"></i></button>
                  <button *ngIf="c.asap == 2 && c.status>=3" type="button" title="Editer" (click)="Edit(c)"
                    class="btn btn-sm btn-primary ml-1 w30"><i class="fa fa-edit"></i></button>
                  <button *ngIf="c.asap == 2 && c.status < 3" type="button" title="Pause" (click)="Pause(c)"
                    class="btn btn-sm btn-success ml-1 w30"><i class="fa fa-pause"></i></button>

                </div>
              </td>
              <td *ngIf="activeTab==2">

                <div class="inline">
                  <button type="button" title="Dupliquer" (click)="Copy(c)" class="btn btn-sm btn-warning ml-1 w30"><i
                      class="fa fa-clone"></i></button>
                  <button *ngIf="c.asap == 2" type="button" title="Replacer en pause" (click)="UnArchive(c)"
                    class="btn btn-sm btn-success ml-1 w30"><i class="fa fa-pause"></i></button>

                  <button *ngIf="c.priority>=1" type="button" title="Détails" (click)="Details(c)"
                    class="btn btn-sm btn-info ml-1 w30"><i class="fa fa-list-ul"></i></button>
                  <button *ngIf="c.priority>=1" type="button" title="Retours" (click)="RcvdMessages(c)"
                    class="btn btn-sm btn-warning ml-1 w30">{{c.rcvdmessages}}</button>

                  <button type="button" title="Supprimer" (click)="Delete(c)" class="btn btn-sm btn-danger ml-1 w30"><i
                      class="fa fa-trash-o"></i></button>

                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="inline">
          <div class="">
            Total: {{(total$ | async)}}
          </div>
          <div class="ml-auto">
            <ngb-pagination class="d-flex justify-content-end" [collectionSize]="total$ | async"
              [(page)]="pagingService.page" [boundaryLinks]="true" [rotate]="true" [directionLinks]="true"
              [pageSize]="pagingService.pageSize" [maxSize]="3" name="page">
            </ngb-pagination>
          </div>
        </div>

      </div>

    </div>
  </div>
</div>

<app-modal [(isActive)]="showMsgModal" [overlayDismiss]="true" (overlayClick)="showMsgModal=false" [isXLarge]="true"
  class="modal-dialog modal-dialog-scrollable">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Messages retours de la campagne '{{selectedCampaign?.name}}'</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="showMsgModal=false">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">

      <div class="p-20">
        <pre
          style='border-radius: 6px; border-color: black; border-width: 1px; padding: 5px; border-style: solid; background: #eee;'>{{selectedCampaign?.message}}</pre>
      </div>

      <div style="max-height: 400px;overflow-y: auto;">
        <table class="table table-striped wrapped">
          <thead>
            <tr>
              <th style="width:18%">Date Réception</th>
              <th style="width:14%">N° mobile</th>
              <th style="width:30%">Champs</th>
              <th>Message</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let m of messages">
              <td>{{m.datereception}}</td>
              <th scope="row">{{m.mobile}}</th>
              <td class="wrapped">{{m.fields}}</td>
              <td>{{m.message}}</td>
            </tr>
            <tr *ngIf="messages?.length==0">
              <td colspan="4" align="center">
                <h2>Pas de messages reçus</h2>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="modal-footer">
      <button class="btn btn-primary" (click)="MailMessages()">Envoyer par Mail</button>
      <button type="button" class="btn btn-secondary" (click)="showMsgModal=false">Fermer</button>
    </div>
  </div>
</app-modal>



<app-modal [(isActive)]="showDetailsModal" [overlayDismiss]="true" (overlayClick)="showDetailsModal=false"
  [isXLarge]="true" class="modal-dialog modal-dialog-scrollable">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Statistiques de la campagne '{{selectedCampaign?.name}}'</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="showDetailsModal=false">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">


      <div class="p-20">
        <table class="table table-nolines">
          <tr>
            <td colspan="2">Total: {{stats?.total}}</td>
            <td colspan="2">Début: {{stats?.sendDate}}</td>
            <td colspan="2">Fin: {{stats?.endDate}}</td>
          </tr>
          <tr>
            <td>{{stats?.progress100}}%</td>
            <td>Délivrés: {{stats?.received}}</td>
            <td>En attente: {{stats?.sent}}</td>
            <td>Expirés: {{stats?.expired}}</td>
            <td>Erreurs: {{stats?.error}}</td>
            <td>Blacklist: {{stats?.blacklist}}</td>
          </tr>
        </table>
        <pre
          style='border-radius: 6px; border-color: black; border-width: 1px; padding: 5px; border-style: solid; background: #eee;'>{{stats?.message}}</pre>
        <form>
          <input type="text" [(ngModel)]="filter.num" placeholder="Filtre sur N° mobile" name="num">
          <div class="btn btn-primary" style="margin-top: -7px; margin-right: 4px" (click)="applyFilter('M')">FIlter
            par N°</div>
          <div class="btn-group">
            <label class="btn btn-info" [ngClass]="{'selectedFilter': filter.status=='D'}"
              (click)="applyFilter('D')">Reçus</label>
            <label class="btn btn-info" [ngClass]="{'selectedFilter': filter.status=='W'}"
              (click)="applyFilter('W')">Envoyés</label>
            <label class="btn btn-info" [ngClass]="{'selectedFilter': filter.status=='X'}"
              (click)="applyFilter('X')">Expirés</label>
            <label class="btn btn-info" [ngClass]="{'selectedFilter': filter.status=='E'}"
              (click)="applyFilter('E')">Erreurs</label>
            <label class="btn btn-info" [ngClass]="{'selectedFilter': filter.status=='A'}"
              (click)="applyFilter('A')">Tous</label>
          </div>
          <div>
            {{historyFiltered?.length}} correspond<span *ngIf="historyFiltered?.length>1">ent</span>
          </div>
        </form>

      </div>
      <div style="max-height: 370px;overflow-y: auto;">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Date MAJ</th>
              <th>N° mobile</th>
              <th>Statut</th>
              <th>Long. Mess.</th>
              <th>Sms</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let h of historyFiltered">
              <td>{{h.date}}</td>
              <th scope="row">{{h.mobile}}</th>
              <td>{{h.label}}</td>
              <td>{{h.messagelen}}</td>
              <td>{{h.sms}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="modal-footer">
      <button class="btn btn-primary" (click)="MailHistory()">Envoyer par Mail</button>
      <button type="button" class="btn btn-secondary" (click)="showDetailsModal=false">Fermer</button>
    </div>
  </div>
</app-modal>

<app-modal [(isActive)]="showTestContact" [overlayDismiss]="true" (overlayClick)="showTestContact=false"
  [isXLarge]="true" class="modal-dialog modal-dialog-scrollable">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Envoi d'un SMS de test</h5>
      <div class="close" data-dismiss="modal" aria-label="Close" (click)="showTestContact=false">
        <span aria-hidden="true">&times;</span>
      </div>
    </div>

    <div class="modal-body">
      <div class="mb-3 w-80">Veuillez saisir les champs pour votre contact (Format en +336xxx ou +337xxx pour le numéro)
      </div>
      <hr>

      <div class="form-group">
        <label for="mobile">N° de mobile</label>
        <input type="text" name="mobile" id="mobile" class="form-control" [(ngModel)]="testVars.mobile"
          autocomplete="off" autocapitalize="off" autocorrect="off">
      </div>

      <form role="form" name="form" class="scrollable">
        <div class="form-group" *ngFor="let f of testVars.fields;let i=index">
          <label for="f{{i}}">{{f}}</label>
          <input type="text" name="f[i]" id="f[i]" class="form-control" [(ngModel)]="testVars.values[i]"
            autocomplete="off" autocapitalize="off" autocorrect="off">
        </div>
      </form>
    </div>

    <div class="modal-footer">
      <div class="btn btn-primary" (click)='SendTest()'>Envoyer</div>
      <div class="btn btn-secondary" data-dismiss="modal" (click)="showTestContact=false">Annuler</div>
    </div>
  </div>
</app-modal>

<app-modal [(isActive)]="showDeleteConfirm" [overlayDismiss]="true" (overlayClick)="showDeleteConfirm=false">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Suppression de la campagne</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="showDeleteConfirm=false">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>Confirmez-vous la suppression de cette campagne ?</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)='DeleteConfirm()'>Confirmer</button>
      <button type="button" class="btn btn-secondary" data-dismiss="modal"
        (click)="showDeleteConfirm=false">Annuler</button>
    </div>
  </div>
</app-modal>

<app-modal [(isActive)]="showCancelConfirm" [overlayDismiss]="true" (overlayClick)="showCancelConfirm=false">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Arrêt de la campagne</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="showCancelConfirm=false">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>Confirmez-vous l'arrêt de cette campagne ?  (Attention, cela n'affecte que les campagnes programmées)</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)='CancelConfirm()'>Confirmer</button>
      <button type="button" class="btn btn-secondary" data-dismiss="modal"
        (click)="showCancelConfirm=false">Annuler</button>
    </div>
  </div>
</app-modal>
