import { Component, OnInit, Input, ViewChildren, QueryList, Injectable, Output, EventEmitter } from '@angular/core';
import { UserService } from 'src/app/providers/user.service';
import { IUser } from 'src/app/interfaces/user';
import { AuthenticationService } from 'src/app/providers/authentication.service';
import { IAnnonce } from 'src/app/interfaces/annonce';

import moment from 'moment';
import * as R from 'ramda';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbdSortableHeader, SortEvent } from 'src/app/directives/sortable.directive';
import { PagingService } from 'src/app/providers/paging.service';
import { ExportDataService } from 'src/app/providers/export-data.service';
import { Observable, of } from 'rxjs';
import { AnnonceService } from 'src/app/providers/annonce.service';
import { AgenceService } from 'src/app/providers/agence.service';
import { ThrowStmt } from '@angular/compiler';
import { Router } from '@angular/router';
import { Agence } from 'src/app/interfaces/agence';


@Component({
  selector: 'app-annonce-list',
  templateUrl: './annonce-list.component.html',
  styleUrls: ['./annonce-list.component.scss'],
})

export class AnnonceListComponent implements OnInit {

  @Input() client: IUser = null;
  @Output() annonceSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() annonceEdit: EventEmitter<any> = new EventEmitter<any>();

  dateChanged = false;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  page = 1;
  pageSize = 10;
  collectionSize = 1;
  searchFields = ['annonceid', 'code_agence', 'reference', 'url', 'contact', 'smstext'];

  agences = [new Agence(0, '', '...', '', '', '')];
  selectedAgence = this.agences[0];

  annonces$: Observable<IAnnonce[]>;
  total$: Observable<number>;
  loading$: Observable<boolean>;
  initialized = false;

  selectedAnnonce = null;
  selected = [];

  constructor(
    public pagingService: PagingService<IAnnonce>,
    private exportDataService: ExportDataService,
    private router: Router,
    private userService: UserService,
    private agenceService: AgenceService,
    private annonceService: AnnonceService,
    private authService: AuthenticationService,
    private spinner: NgxSpinnerService,
  ) {
    this.annonces$ = pagingService.rowsObs;
    this.total$ = pagingService.totalObs;
    this.loading$ = pagingService.loadingObs;
    this.pagingService.searchTerm = '';
    // set fields for filtering
    this.pagingService.fields = this.searchFields;
  }

  ngOnInit() {
    if (!this.client) {
      this.router.navigate(['/']);
    }
    this.pagingService.loadRows(of([]));

    this.agenceService.GetAll(this.client.id).subscribe( result => {
      result.forEach(a => this.agences.push(a));
      if (result.length === 1) {
        // if only 1 agence remove default and keep this agence as single choice
        this.agences.shift();
      }
    });

    // load all annonces
    this.pagingService.loadRows(this.annonceService.GetAll(this.client.id, this.selectedAgence.id));
    this.loading$.subscribe(loading => {
      if (loading) {
        this.spinner.show();
      } else {
        this.spinner.hide();
        this.initialized = true;
      }
    });
    this.pagingService.page = 1;
  }

  pageChanged(event) {
    console.log('onPageChange', event);
  }

  selectAgence(agence) {
    this.pagingService.loadRows(this.annonceService.GetAll(this.client.id, this.selectedAgence.id));
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.pagingService.sortColumn = column;
    this.pagingService.sortDirection = direction;
  }

  applyFilters() {
    // user selected a different codeAgence
    this.pagingService.loadRows(this.annonceService.GetAll(this.client.id, this.selectedAgence.id));
  }

  selectAnnonce(annonce) {
    // signal we select an annonce
    this.annonceSelect.emit(annonce);
  }

  editAnnonce(annonce) {
    this.annonceEdit.emit(annonce);
  }

  Export(kind) {
    console.log('export', kind);
    const records = [];
    const fields = this.searchFields;


    for (const u of this.pagingService.filteredRows) {
      const user = R.pick(fields, u);
      records.push(user);
    }
    const header = {};
    fields.forEach(f => header[f] = f.replace(/[^a-z0-9]/gmi, '').toUpperCase());
    records.unshift(header);
    this.exportDataService.exportAs(kind, records, 'credits_' + moment().format('YYYY-MM-DD') );
  }

}


