import { ViewChildren, QueryList, Component, OnInit, AfterViewInit, Input, OnDestroy } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { SortEvent, NgbdSortableHeader } from 'src/app/directives/sortable.directive';
import { environment } from '../../../environments/environment';

import { PagingService } from '../../providers/paging.service';
import { UserService } from 'src/app/providers/user.service';
import { Router } from '@angular/router';

import * as R from 'ramda';
import { ExportDataService } from 'src/app/providers/export-data.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IModele, Modele } from 'src/app/interfaces/modele';
import { IUser } from 'src/app/interfaces/user';
import { AuthenticationService } from 'src/app/providers/authentication.service';


@Component({
  selector: 'app-admin-manager',
  templateUrl: './admin-manager.component.html',
  styleUrls: ['./admin-manager.component.scss']
})
export class AdminManagerComponent implements OnInit, OnDestroy {

  admins: any[] = null;
  adminSubscribe = null;

  isAdmin = this.authService.isAdmin;
  adminId = this.authService.adminId;
  adminRole = this.authService.adminRole;

  adminDefault =  { id: 0, username: '', phone: '', role: 1 };
  selectedAdmin = this.adminDefault;
  showDeleteConfirm: boolean;

  newAdmin: any;
  showEditAdmin = false;

  constructor(
    private authService: AuthenticationService,
    private userService: UserService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
  ) {
  }

  ngOnInit(): void {
    console.log('admin');
    this.spinner.show();
    this.adminSubscribe = this.userService.GetAdmins(this.adminId, this.adminRole).subscribe( (res: any) => {
      this.admins = R.clone(res);
      this.spinner.hide();
    });
  }

  ngOnDestroy() {
    if (this.adminSubscribe) {
      this.adminSubscribe.unsubscribe();
    }
  }

  Add() {
    this.selectedAdmin = this.adminDefault;
    this.showEditAdmin = true;
  }

  Edit(admin) {
    this.selectedAdmin = admin;
    this.showEditAdmin = true;
  }

  UpdateAdmin() {
    this.spinner.show();

    if (this.selectedAdmin.id > 0) {
        this.userService.UpdateAdmin(this.selectedAdmin)
        .subscribe((response: any) => {
            if (response.success) {
                this.toastr.success('L\'administrateur \'' + this.selectedAdmin.username + '\' a été modifié');
                this.ngOnInit();
            } else {
              this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
            }
            this.spinner.hide();
        });
    } else {
      this.userService.AddAdmin(this.selectedAdmin)
        .subscribe((response: any) => {
            if (response.success) {
              this.toastr.success('L\'administrateur \'' + this.selectedAdmin.username + '\' a été ajouté');
              this.ngOnInit();
            } else {
              this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
            }
            this.spinner.hide();
        });
    }
    this.showEditAdmin = false;

  }


  Delete(admin) {
    this.selectedAdmin = admin;
    this.showDeleteConfirm = true;
    console.log('show delete confirm');
  }

  DeleteConfirm() {
    this.showDeleteConfirm = false;
    console.log('call delete api');
    this.spinner.show();

    this.userService.DeleteAdmin(this.selectedAdmin.id).subscribe((response: any) => {
      if (response.success) {
        this.toastr.success('L\'administrateur a été supprimé', '');
        this.ngOnInit();
      } else {
        this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
      }
      this.spinner.hide();
    });
  }

}
