import { ViewChildren, QueryList, Component, OnInit, OnDestroy, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SortEvent, NgbdSortableHeader } from 'src/app/directives/sortable.directive';

import { PagingService } from '../../providers/paging.service';
import { UserService } from 'src/app/providers/user.service';
import { Router } from '@angular/router';

import * as R from 'ramda';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { IUser } from 'src/app/interfaces/user';
import { AuthenticationService } from 'src/app/providers/authentication.service';

import { ExportDataService } from 'src/app/providers/export-data.service';

import moment from 'moment';


@Component({
  selector: 'app-conso-postpaid',
  templateUrl: './conso-postpaid.component.html',
  styleUrls: ['./conso-postpaid.component.scss']
})
export class ConsoPostpaidComponent implements OnInit, OnDestroy {

  users$: Observable<IUser[]>;
  total$: Observable<number>;
  loading$: Observable<boolean>;

  selectedUser: IUser = null;
  client: IUser = null;
  userSubscribe = null;

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  page = 1;
  pageSize = 10;
  collectionSize = 1;
  searchFields = ['id', 'username', 'company', 'premium', 'lowcost'];
  isAdmin = this.authService.isAdmin;

  showResetModal = false;
  initialized = false;

  period = {
    startdate: new Date(moment().format('YYYY-MM-01')),
    stopdate: new Date(moment().format('YYYY-MM-') + moment().endOf('month').format('DD'))
  };
  origPeriod = R.clone(this.period);

  selectedPeriod = {
    startdate: moment(this.period.startdate).format('YYYY-MM-DD'),
    stopdate: moment(this.period.stopdate).format('YYYY-MM-DD'),
    admin: this.authService.adminId,
  };

  conso: any;
  consoSubscribe = null;

  showAddCredit = false;
  creditType = 1;   // premium=1, lowcost=0
  credits = 0;

  activeTab = 0;

  // // provide your own if necessary
  // providers: [{provide: NgbDatepickerI18n, useClass: YourOwnDatepickerI18n}]

  constructor(
    public pagingService: PagingService<IUser>,
    private authService: AuthenticationService,
    private userService: UserService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private exportDataService: ExportDataService,
    private router: Router,
  ) {
    // this.client = new User();
    // this.client.id = 0;
    this.users$ = pagingService.rowsObs;
    this.total$ = pagingService.totalObs;
    this.loading$ = pagingService.loadingObs;
    this.pagingService.searchTerm = '';
    // set fields for filtering
    this.pagingService.fields = this.searchFields;

    this.pagingService.loadRows(of([]));

    this.pagingService.loadRows(this.userService.GetCreditsPostpaid(this.authService.adminId));
    this.consoSubscribe = this.userService.GetPostPaidCounter().subscribe( (conso: any) => {
      this.conso = conso;
    });

    this.loading$.subscribe(loading => {
      if (loading) {
        this.spinner.show();
      } else {
        setTimeout(() => {
          this.spinner.hide();
        }, 500);
        this.initialized = true;
      }
    });
    this.pagingService.page = 1;
  }

  ngOnInit(): void {

    console.log('credits onInit', this.client);
    // if (!this.client) {
    //   this.router.navigate(['/']);
    // }

  }

  ngOnDestroy() {
    if (this.userSubscribe) {
      this.userSubscribe.unsubscribe();
    }
    if (this.consoSubscribe) {
      this.consoSubscribe.unsubscribe();
    }
  }

  pageChanged(event) {
    console.log('onPageChange', event);
  }

  selectTab(tab) {
    this.activeTab = tab;
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.pagingService.sortColumn = column;
    this.pagingService.sortDirection = direction;
  }

  selectUser(user) {
    this.selectedUser = R.clone(user);
  }


  Export(kind) {
    console.log('export', kind);
    const records = [];
    const fields = ['creditid', 'campaignid', 'company', 'label', 'dateoperation', 'premium', 'lowcost'];

    for (const u of this.pagingService.filteredRows) {
      const user = R.pick(fields, u);
      records.push(user);
    }
    const header = {};
    fields.forEach(f => header[f] = f.replace(/[^a-z0-9]/gmi, '').toUpperCase());
    records.unshift(header);
    this.exportDataService.exportAs(kind, records, 'credits_' + moment().format('YYYY-MM-DD') );
  }

  selectAndShowCampaigns(user) {
    this.userService.GetById(user.id).subscribe( (res: any) => {
      this.router.navigate(['/campagne/status/encours']);
    });
  }

  selectAndShowCredits(user) {
    this.userService.GetById(user.id).subscribe( (res: any) => {
      this.router.navigate(['/client/credit']);
    });
  }

  postpaidSendMail() {
    this.userService.SendPostpaidMail(this.authService.adminId, this.authService.currentUser)
    .subscribe( (response: any) => {
      if (response.success) {
        this.toastr.success('Le mail a été envoyé', '');
      } else {
        this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
      }
    });
  }

  postpaidResetCounter() {
    this.showResetModal = true;
  }

  postpaidResetCounterConfirm() {
    this.showResetModal = false;
    this.spinner.show();
    this.userService.ResetPostpaidCredits(this.authService.adminId, this.authService.currentUser).subscribe( (response: any) => {
      if (response.success) {
        this.toastr.success('RAZ effectuée', '');
        this.pagingService.loadRows(this.userService.GetCreditsPostpaid(this.authService.adminId));
      } else {
        this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
      }
    });
  }

}
