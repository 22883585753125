
export interface IBlacklist {
  id: number;
  customerid: number;
  mobile: string;
}

export class Blacklist {
  id: number;
  customerid: number;
  mobile: string;
  constructor(id, customerid, mobile) {
    this.id = id;
    this.customerid = id;
    this.mobile = mobile;
  }
}
