export interface IModele {
  id: number;
  customerid: number;
  datemaj: string;
  islast: number;
  label: string;
  message: string;
  longueur: number;
  sms: number;
}

export class Modele {
  id: number;
  customerid: number;
  datemaj: string;
  islast: number;
  label: string;
  message: string;
  longueur: number;
  sms: number;
  constructor(customerid, islast, label, message, longueur, sms) {
    this.id = 0;
    this.customerid = customerid;
    this.islast = islast;
    this.label = label;
    this.message = message;
    this.longueur = longueur;
    this.sms = sms;
    this.datemaj = '';
  }
}
