import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AllianceDistributionService {

  constructor(
    private httpClient: HttpClient,
  ) { }


  GetAdeAllStat(clientid, period): Observable<any> {
    const url = environment.apiUrl + '/ades/stathistory/' + clientid;
    return this.httpClient.post(url, period).pipe(map( (res: any) => res.formations));
  }

  GetAdeStage(stageid): Observable<any> {
    const url = environment.apiUrl + '/ades/stage/' + stageid;
    return this.httpClient.get(url);
  }

  CreateAdeStage(clientid, stage): Observable<any> {
    const url = environment.apiUrl + '/ades/stage/' + clientid;
    return this.httpClient.post(url, stage);
  }

  UpdateAdeStage(clientid, stage): Observable<any> {
    const url = environment.apiUrl + '/ades/stage/' + clientid + '/' + stage.id;
    return this.httpClient.put(url, stage);
  }

  SetCampaignID(clientid, stageid, campaignid, contactid): Observable<any> {
    const url = environment.apiUrl + '/ades/setcampaign/' + clientid + '/' + stageid + '/' + campaignid + '/' + contactid;
    return this.httpClient.get(url);
  }

  GetAdeStagiaires(stageid): Observable<any> {
    const url = environment.apiUrl + '/ades/stagiaires/' + stageid;
    return this.httpClient.get(url).pipe(map( (res: any) => res.stagiaires));
  }

  SaveAdeStagiaire(stageid, stagiaire): Observable<any> {
    const url = environment.apiUrl + '/ades/stagiaire/' + stageid;
    return this.httpClient.post(url, stagiaire);
  }

}
