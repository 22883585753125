import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BlacklistService {

  constructor(
    private httpClient: HttpClient,
  ) { }

  AdminGetAll(adminid): Observable<any> {
    const url = environment.apiUrl + '/blacklists/admin' + adminid;
    return this.httpClient.get(url);
  }

  GetAll(clientid): Observable<any> {
    const url = environment.apiUrl + '/blacklists/user/' + clientid;
    return this.httpClient.get(url);
  }

  Add(clientid, black): Observable<any> {
    const url = environment.apiUrl + '/blacklists/' + clientid;
    return this.httpClient.post(url, black);
  }

  Delete(clientid, blackid): Observable<any> {
    const url = environment.apiUrl + '/blacklists/' + clientid + '/' + blackid;
    return this.httpClient.delete(url);
  }

}
