import { Component, OnInit } from '@angular/core';

import { AuthenticationService } from 'src/app/providers/authentication.service';

import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.scss']
})
export class AdminLoginComponent implements OnInit {

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  siteurl = 'isisms.fr';
  siteicon = './assets/img/logo_isi_sms_blanc.png';

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService) { }

  ngOnInit() {

    console.log('init user-login');


    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService.loginAdmin(this.f.username.value, this.f.password.value)
      .subscribe(
        data => {
          this.error = '';
          console.log('user logged in', data);
          this.router.navigate(['/']);
        },
        error => {
          console.log('user failure', error);
          this.error = error.message;
          this.loading = false;
        });
  }

  passwordRequest() {
    let username = this.f.username.value;
    if (username === '') { username = '-'; }
    this.router.navigate([`/password_request/1/${username}`]);
  }

}
