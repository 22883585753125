import { ViewChildren, QueryList, Component, OnInit, AfterViewInit, Input, OnDestroy } from '@angular/core';
import { Observable, ReplaySubject, of } from 'rxjs';
import { SortEvent, NgbdSortableHeader } from 'src/app/directives/sortable.directive';

import { PagingService } from '../../providers/paging.service';
import { UserService } from 'src/app/providers/user.service';
import { Router, ActivatedRoute } from '@angular/router';

import * as R from 'ramda';
import { ExportDataService } from 'src/app/providers/export-data.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IModele, Modele } from 'src/app/interfaces/modele';
import { IUser } from 'src/app/interfaces/user';
import { CampaignService } from 'src/app/providers/campaign.service';
import { AuthenticationService } from 'src/app/providers/authentication.service';
import { ContactListService } from 'src/app/providers/contact-list.service';

@Component({
  selector: 'app-campaign-list',
  templateUrl: './campaign-list.component.html',
  styleUrls: ['./campaign-list.component.scss']
})
export class CampaignListComponent implements OnInit, OnDestroy {

  campaigns$: Observable<IModele[]>;
  total$: Observable<number>;
  loading$: Observable<boolean>;

  users: IModele[] = [];
  clientSubscribe = null;
  reloadInterval = null;
  selectedCampaign: any;

  @Input() client: IUser;

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  page = 1;
  pageSize = 10;
  collectionSize = 1;
  searchFields = ['id', 'name', 'type', 'datemodification', 'total', 'messagelen'];

  showCancelConfirm = false;
  showDetailsModal = false;
  showMsgModal = false;
  showDeleteConfirm = false;
  modalHeaderText = '';
  modalBodyText = '';
  ModalConfirm = null;

  showAddModele = false;
  showEditCampaign = false;
  initialized = false;

  activeTab = 0;
  campaignType = ['En préparation', 'En cours', 'Archivées'];

  history: any;
  historyFiltered: any;
  filter = { num: '', status: 'A'};
  stats: any;
  messages: any;

  isLoading = false;

  lists = [];
  showTestContact = false;
  testVars = {
    isUnitTest: true,
    mobile: '',
    message: '',
    fields: [],
    mandatory: [],
    values: [],
  };
  testMobile = '';
  fields = [];

  constructor(
    public pagingService: PagingService<IModele>,
    private userService: UserService,
    private campaignService: CampaignService,
    private contactListService: ContactListService,
    private exportDataService: ExportDataService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private authService: AuthenticationService,
  ) {
    console.log('campaign ctor');
    this.campaigns$ = pagingService.rowsObs;
    this.total$ = pagingService.totalObs;
    this.loading$ = pagingService.loadingObs;
    this.pagingService.searchTerm = '';
    // set fields for filtering
    this.pagingService.fields = this.searchFields;
    this.initialized = false;

    switch (this.activatedRoute.snapshot.params.status) {
      case 'encours':
        this.activeTab = 1;
        break;
      case 'archivee':
        this.activeTab = 2;
        break;
      case 'preparation':
      default:
        this.activeTab = 0;
        break;
    }
  }

  ngOnInit(): void {

    console.log('campaign onInit', this.client);
    this.pagingService.loadRows(of([]));

    this.clientSubscribe = this.userService.selectedUser$.subscribe(user => {
      this.client = R.clone(user);
      if (this.client) {
        this.pagingService.loadRows(this.campaignService.GetAll(this.client.id, this.activeTab));
        this.loading$.subscribe(loading => {
          if (loading) {
            this.spinner.show();
          } else if (!this.isLoading) {
            setTimeout(() => {
              this.spinner.hide();
              this.initialized = true;
            }, 600);
          }
        });
        this.pagingService.page = 1;
      }
    });

    this.reloadInterval = setInterval(() => {
      this.pagingService.loadRows(this.campaignService.GetAll(this.client.id, this.activeTab));
    }, 30000);
  }

  ngOnDestroy() {
    console.log('campaign list onDestroy');
    if (this.clientSubscribe) {
      this.clientSubscribe.unsubscribe();
    }
    if (this.reloadInterval) {
      clearInterval(this.reloadInterval);
    }
  }

  selectTab(tab) {
    this.activeTab = tab;
    const links = [ 'preparation', 'encours', 'archivee'];
    this.router.navigate(['/campagne/status/' + links[tab]]);
    if (this.client && this.client.id > 0) {
      this.spinner.show();
      this.initialized = false;
      this.pagingService.loadRows(this.campaignService.GetAll(this.client.id, this.activeTab));
    }
  }

  pageChanged(event) {
    console.log('onPageChange', event);
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.pagingService.sortColumn = column;
    this.pagingService.sortDirection = direction;
  }

  selectCampaign(campaign) {
    this.selectedCampaign = R.clone(campaign);
  }


  Add() {
    if (!this.client || this.client.id === 0) { return; }
    this.selectedCampaign = new Modele(this.client.id, false, '', '', 0, 0);
    this.showEditCampaign = true;
  }

  Export(kind) {
    console.log('export', kind);
    const records = [];
    const fields = ['id', 'name', 'type', 'datemodification', 'total', 'messagelen'];
    for (const u of this.pagingService.filteredRows) {
      const model = R.pick(fields, u);
      records.push(model);
    }
    const header = {};
    fields.forEach(f => header[f] = f.replace(/[^a-z0-9]/gmi, '').toUpperCase());
    records.unshift(header);
    this.exportDataService.exportAs(kind, records, 'user');
  }

  Delete(campaign) {
    this.selectedCampaign = campaign;
    this.showDeleteConfirm = true;
    // console.log('show delete confirm');
  }

  DeleteConfirm() {
    this.showDeleteConfirm = false;
    this.spinner.show();
    this.campaignService.Delete(this.client.id, this.selectedCampaign.id).subscribe((response: any) => {
      if (response.success) {
        this.toastr.success('La campagne a été supprimée', '');
        this.pagingService.loadRows(this.campaignService.GetAll(this.client.id, this.activeTab));
      } else {
        this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
      }
      this.spinner.hide();
    });
  }

  Cancel(campaign) {
    this.selectedCampaign = R.clone(campaign);
    this.showCancelConfirm = true;
  }
  CancelConfirm() {
    this.showCancelConfirm = false;
    this.spinner.show();
    this.campaignService.Cancel(this.client.id, this.selectedCampaign.id).subscribe((response: any) => {
      if (response.success) {
        this.toastr.success('La campagne est annulée. Elle va être archivée', '');
        setTimeout(() => {
          this.Archive(this.selectedCampaign);
        }, 1000 );
      } else {
        this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
      }
      this.spinner.hide();
    });
  }

  Details(campaign) {
    this.selectedCampaign = R.clone(campaign);
    this.spinner.show();
    this.isLoading = true;
    this.showDetailsModal = true;
    this.campaignService.GetHistory(this.client.id, this.selectedCampaign.id)
    .subscribe((data: any) => {
      // console.log(data);
      if (data.success) {
        this.history = R.clone(data.history);
        this.historyFiltered = R.clone(data.history);
        this.stats = R.clone(data.stats);
      } else {
        this.toastr.error(data.message, 'Erreur', { timeOut: 0 });
      }
      this.isLoading = false;
      this.spinner.hide();
    });
  }

  applyFilter(status) {
    this.spinner.show();
    this.isLoading = true;
    setTimeout(() => {

      if (status === 'M') {
        if (this.filter.num.length > 1 && this.filter.num[0] === '0') {
          this.filter.num =  this.filter.num.substr(1);
        }
      } else {
        this.filter.status = status;
      }
      const filterNum = (this.filter.num.length > 0);

      const filterArray = [];
      if (status === 'D') {
              filterArray.push(6);
      } else if (status === 'W') {
              filterArray.push(1);
              filterArray.push(3);
              filterArray.push(4);
      } else if (status === 'X') {
              filterArray.push(12);
      } else if (status === 'E') {
              filterArray.push(2);
              filterArray.push(11);
              filterArray.push(13);
              filterArray.push(14);
              filterArray.push(15);
              filterArray.push(16);
              filterArray.push(23);
      }

      this.historyFiltered = this.history.filter(h =>
            h.mobile.indexOf(this.filter.num) !== -1  &&
            ( filterArray.length === 0 || filterArray.indexOf(h.status) !== -1)
      );
      this.spinner.hide();
      this.isLoading = false;
    }, 10);
  }

  MailHistory() {
    if (this.authService.isAdmin) {

      this.campaignService.MailReportAdmin(this.client.id, this.selectedCampaign.id, this.authService.adminId)
      .subscribe((response: any) => {
        if (response.success) {
          this.toastr.success('ADMIN: Le rapport de campagne a été envoyé', '');
        } else {
          this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
        }
      });
    } else {
      this.campaignService.MailReport(this.client.id, this.selectedCampaign.id)
        .subscribe((response: any) => {
        if (response.success) {
          this.toastr.success('Le rapport de campagne a été envoyé', '');
        } else {
          this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
        }
      });
    }
  }

  MailMessages() {

    if (this.authService.isAdmin) {

      this.campaignService.MailRcvdMessagesAdmin(this.client.id, this.selectedCampaign.id, this.authService.adminId)
      .subscribe((response: any) => {
        if (response.success) {
          this.toastr.success('ADMIN: Le rapport des messages reçus a été envoyé', '');
        } else {
          this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
        }
      });
    } else {
      this.campaignService.MailRcvdMessages(this.client.id, this.selectedCampaign.id)
        .subscribe((response: any) => {
        if (response.success) {
          this.toastr.success('Le rapport des messages reçus a été envoyé', '');
        } else {
          this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
        }
      });
    }
  }


  RcvdMessages(campaign) {
    this.selectedCampaign = R.clone(campaign);
    this.spinner.show();
    this.isLoading = true;
    this.showMsgModal = true;
    this.campaignService.GetRcvdMessages(this.client.id, this.selectedCampaign.id)
    .subscribe((data: any) => {
      this.messages = R.clone(data);
      this.historyFiltered = R.clone(data.history);
      this.stats = R.clone(data.stats);
      this.isLoading = false;
      this.spinner.hide();
    });
  }

  Archive(campaign) {
    this.selectedCampaign = R.clone(campaign);
    this.campaignService.Archive(this.client.id, this.selectedCampaign.id)
    .subscribe((response: any) => {
      if (response.success) {
        this.toastr.success('La campagne est archivée.', '');
        this.pagingService.loadRows(this.campaignService.GetAll(this.client.id, this.activeTab));
      } else {
        this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
      }
      this.spinner.hide();
    });
  }
  UnArchive(campaign) {
    this.selectedCampaign = R.clone(campaign);
    this.campaignService.UnArchive(this.client.id, this.selectedCampaign.id)
    .subscribe((response: any) => {
      if (response.success) {
        this.toastr.success('La campagne est active.', '');
        this.pagingService.loadRows(this.campaignService.GetAll(this.client.id, this.activeTab));
      } else {
        this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
      }
      this.spinner.hide();
    });
  }

  Resume(campaign) {
    this.selectedCampaign = R.clone(campaign);
    this.campaignService.Resume(this.client.id, this.selectedCampaign.id)
    .subscribe((response: any) => {
      if (response.success) {
        this.toastr.success('La campagne est redemarrée.', '');
        this.pagingService.loadRows(this.campaignService.GetAll(this.client.id, this.activeTab));
      } else {
        this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
      }
      this.spinner.hide();
    });
  }

  Edit(campaign) {
    this.selectedCampaign = R.clone(campaign);
    this.campaignService.selectedCampaign = this.selectedCampaign;
    this.router.navigate(['/campagne/edition/' + this.selectedCampaign.id]);
  }

  Pause(campaign) {
    this.selectedCampaign = R.clone(campaign);
    this.campaignService.Pause(this.client.id, this.selectedCampaign.id)
    .subscribe((response: any) => {
      if (response.success) {
        this.toastr.success('La campagne est en pause.', '');
        this.pagingService.loadRows(this.campaignService.GetAll(this.client.id, this.activeTab));
      } else {
        this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
      }
      this.spinner.hide();
    });
  }
  Copy(campaign) {
      this.selectedCampaign = R.clone(campaign);
      this.campaignService.selectedCampaign = R.clone(campaign);

      this.router.navigate(['/campagne/edition/copy']);
  }

  Start(campaign) {
    this.selectedCampaign = R.clone(campaign);
    this.spinner.show();
    this.campaignService.Start(this.client.id, this.selectedCampaign.id)
    .subscribe((response: any) => {
        if (response.success) {
          this.toastr.success('La campagne est validée. Elle démarrera automatiquement', '');
          this.activeTab = 1;
          this.pagingService.loadRows(this.campaignService.GetAll(this.client.id, this.activeTab));
        } else {
          this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
        }
        this.spinner.hide();
    });
  }

  Test(campaign) {
    this.selectedCampaign = campaign;
    this.spinner.show();

    this.contactListService.GetAll(this.client.id)
    .subscribe((lists: any[]) => {
      this.lists = lists;

      // reset selected flags on lists
      this.lists.forEach(l => l.selected = 0);

      // set selected only for lists presents in campaign.lists
      this.selectedCampaign.lists.forEach((list) => {
        this.lists.forEach(l => {
          if (l.id === list.listid) {
            l.selected = 1;
          }
        });
      });

      // compute fields for this campaign
      this.fields = this.campaignService.intersectFields(this.selectedCampaign, this.lists);

      // display form to get contact info and send
      this.testVars = {
        isUnitTest: true,
        mobile: this.testMobile,
        message: this.selectedCampaign.message,
        fields: this.fields,
        mandatory: Array(this.fields.length).join('.').split('.'),
        values: Array(this.fields.length).join('.').split('.')   // init an empty array
      };
      this.showTestContact = true;
      this.spinner.hide();
    });
  }

  SendTest() {
    this.testMobile = this.testVars.mobile;
    this.spinner.show();
    this.campaignService.Test(this.client.id, this.selectedCampaign.id, this.testVars)
    .subscribe((response: any) => {
      if (response.success) {
        this.toastr.success('Le message de test a été envoyé');
      } else {
        this.toastr.error(response.message, '', { timeOut: 0 });
      }
      this.spinner.hide();
    });
    this.showTestContact = false;

  }


}
