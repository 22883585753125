<ngx-spinner bdOpacity=0.2 bdColor="rgba(51,51,51,0)" size="medium" color="#515151" type="ball-pulse-sync" [fullScreen]="false">
</ngx-spinner>

<div class="card">
  <div class="card-body">

    <form class="form-inline">
      <div class="form-group">
        <button class="btn btn-success" (click)="Add()">
          <i class="fa fa-plus"></i>&nbsp;Ajouter un modèle</button>
      </div>
    </form>
    <br>


    <div class="clearfix">
      <form class="inline">
        <!-- <button type="button" (click)="Export('TEXT')"
            class="btn btn-sm btn-outline-secondary ml-2 h-100">Copy</button>
          <button type="button" (click)="Export('CSV')" class="btn btn-sm btn-outline-secondary ml-2 h-100">CSV</button>
          <button type="button" (click)="Export('EXCEL')"
            class="btn btn-sm btn-outline-secondary ml-2 h-100">Excel</button>
          <button type="button" (click)="Export('PRINT')"
            class="btn btn-sm btn-outline-secondary ml-2 h-100">Print</button> -->

        <div class="form-group form-inline ml-auto">
          Recherche: <input class="form-control ml-2" type="text" name="searchTerm"
            [(ngModel)]="pagingService.searchTerm" />
        </div>
      </form>

      <table id="selectedColumn" class="table table-bordered table-striped table-hover table-sm" cellspacing="0"
        width="100%">
        <thead>
          <tr>
            <th class="th" sortable="id" (sort)="onSort($event)">ID</th>
            <th class="th collapsible" sortable="label" (sort)="onSort($event)">Titre</th>
            <th class="th collapsible" sortable="message" (sort)="onSort($event)">Message</th>
            <th class="th collapsible">Long./SMS</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody *ngIf="initialized">
          <tr *ngFor="let modele of modeles$ | async" (click)="selectModele(modele)"
            [ngClass]="{'selected': modele.id === selectedModele?.id}">
            <td>{{modele.id}}</td>
            <td>
              <ngb-highlight [result]="modele.label" [term]="pagingService.searchTerm"></ngb-highlight>
            </td>
            <td class="collapsible">
              <ngb-highlight [result]="modele.message" [term]="pagingService.searchTerm">
              </ngb-highlight>
            </td>
            <td class="collapsible">{{modele.longueur}} / {{modele.sms}}
            </td>
            <td>
              <div class="inline">
                <button type="button" title="Editer" (click)="Edit(modele)" class="btn btn-sm btn-primary ml-1 w30"><i
                    class="fa fa-edit"></i></button>
                <button type="button" title="Supprimer" (click)="Delete(modele)"
                  class="btn btn-sm btn-danger ml-1 w30"><i class="fa fa-trash-o"></i></button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="inline">
        <div class="">
          Total: {{(total$ | async)}}
        </div>
        <div class="ml-auto">
          <ngb-pagination class="d-flex justify-content-end" [collectionSize]="total$ | async"
            [(page)]="pagingService.page" [boundaryLinks]="true" [rotate]="true" [directionLinks]="true"
            [pageSize]="pagingService.pageSize" [maxSize]="3" name="page">
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>


<app-modal [(isActive)]="showDeleteConfirm" [overlayDismiss]="true" (overlayClick)="showDeleteConfirm=false">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Suppression du modèle</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="showDeleteConfirm=false">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>Confirmez-vous la suppression de ce modèle ?</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)='DeleteConfirm()'>Confirmer</button>
      <button type="button" class="btn btn-secondary" data-dismiss="modal"
        (click)="showDeleteConfirm=false">Annuler</button>
    </div>
  </div>
</app-modal>


<app-modal [(isActive)]="showEditModele" [overlayDismiss]="false" [isLarge]="true">
  <div class="modal-dialog modal-dialog-scrollable">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">{{selectedModele.id == 0 ? 'Ajout' : 'Edition'}} modèle</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="showEditModele=false">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form role="form" name="form">

        <div class="form-group">
          <label>Saisissez le titre du modèle</label>
          <input type="text" name="label" class="form-control" [(ngModel)]="selectedModele.label"
            autocomplete="off" autocapitalize="off" autocorrect="off" #labelRef="ngModel"
            [ngClass]="{ 'is-invalid': labelRef.invalid }" required />
          <span *ngIf="labelRef.dirty && labelRef.errors?.required" class="text-danger"> Saisissez un titre</span>
        </div>

        <div class="form-group">
          <label for="message">Message</label>
          <textarea name="message" id="message" class="form-control" style="resize:vertical;margin-bottom:10px;" rows="5" [(ngModel)]="selectedModele.message" (ngModelChange)="computeMsgLen()"></textarea>
          <span class="help-block">Longueur {{computed.long}} / {{computed.sms}} SMS</span>
        </div>
      </form>

    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)='UpdateModele()'>{{ selectedModele.id == 0 ? 'Ajouter' : 'Modifier'}}</button>
      <button type="button" class="btn btn-secondary" data-dismiss="modal"
        (click)="showEditModele=false">Annuler</button>
    </div>
  </div>
  </div>
</app-modal>
