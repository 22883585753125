<div class="client-view p-md-4">

  <app-client-select [selectedUser]="client"></app-client-select>

  <ngx-spinner bdOpacity=0.2 bdColor="rgba(51,51,51,0)" size="medium" color="#515151" type="ball-pulse-sync"
    [fullScreen]="false">
  </ngx-spinner>

  <ul class="nav nav-pills mb-n3" id="myTab" role="tablist">
    <li class="nav-item">
      <div class="nav-link" [ngClass]="{'active': activeTab==='coord'}" id="coord-tab" data-toggle="tab"
        (click)="selectTab('coord')" role="tab" aria-controls="coord" aria-selected="true">Identifiants /
        Coordonnées</div>
    </li>
    <li class="nav-item">
      <div class="nav-link" [ngClass]="{'active': activeTab==='modeles'}" id="profile-tab" data-toggle="tab"
        (click)="selectTab('modeles')" role="tab" aria-controls="modeles" aria-selected="false">Modèles</div>
    </li>
    <li class="nav-item">
      <div class="nav-link" [ngClass]="{'active': activeTab==='factures'}" id="contact-tab" data-toggle="tab"
        (click)="selectTab('factures')" role="tab" aria-controls="factures" aria-selected="false">Factures</div>
    </li>
    <!-- <li class="nav-item" *ngIf="client?.option_mosms">
      <div class="nav-link" [ngClass]="{'active': activeTab==='agences'}" id="contact-tab" data-toggle="tab"
        (click)="selectTab('agences')" role="tab" aria-controls="agences" aria-selected="false">Agences</div>
    </li> -->
  </ul>
  <hr>

  <div class="tab-content" id="myTabContent">

    <div class="tab-pane fade show active" id="coord" role="tabpanel" aria-labelledby="coord-tab"
      *ngIf="activeTab === 'coord'">

      <div class="card">
        <div class="card-body">

          <div class="alert alert-danger" role="alert" *ngIf="!client">
            Sélectionner un client !
          </div>

          <form name="form" #f="ngForm" autocomplete="off" autocapitalize="off" autocorrect="off" *ngIf="client">
            <div class="section">
              <h4 class="txt18 bold">Identifiants :</h4>
              <!-- {{client|json}} -->
              <div class="row">

                <div class="form-group col-sm-6 col-xs-12">
                  <label for="username">Votre Email</label>
                  <input type="text" name="username" [(ngModel)]="client.username" class="form-control"
                    [disabled]="!isAdmin" autocomplete="off" autocapitalize="off" autocorrect="off" required email
                    #usernameRef="ngModel" [ngClass]="{ 'is-invalid': usernameRef.invalid }" />
                  <div *ngIf="(usernameRef.dirty && usernameRef.errors?.email) || client.email_invalid==1"
                    class="text-danger">Email invalide</div>
                  <div *ngIf="usernameRef.dirty && usernameRef.errors?.required" class="text-danger">Champ obligatoire
                  </div>
                </div>

                <div class="form-group col-sm-6 col-xs-12" [ngClass]="{ 'has-error': client.email_invalid==1 }">
                  <label for="username">Clef d'API</label>
                  <input type="text" name="apikey" [(ngModel)]="client.apikey" class="form-control" readonly
                    autocomplete="off" autocapitalize="off" autocorrect="off" />
                  <div class="btn btn-primary" (click)="genkey()">
                    <i class="fa fa-cog"></i>&nbsp;&nbsp;Génerer une clef</div>
                  <div class="btn btn-info" (click)="copyKey()">
                    <i class="fa fa-copy"></i>&nbsp;&nbsp;Copier</div>
                  <div *ngIf="showSaveKey" class="help-block text-warning blink">N'oubliez pas de sauvegarder vos
                    informations</div>
                  <br>
                </div>
              </div>
              <br>
              <div class="form-row">
                <div class="form-group col-sm-6 col-xs-12" *ngIf="isAdmin && client.id != 0">
                  <div *ngIf="client?.disabled==2">
                    <h5>Le client est en attente de validation </h5>
                    <div class="btn btn-warning" (click)="Disable(0)">Valider le client</div>
                  </div>
                  <div *ngIf="client?.disabled==1">
                    <h5>Le client est suspendu !!</h5>
                    <div class="btn btn-warning" (click)="Disable(0)">Activer le client</div>
                  </div>
                  <div *ngIf="client?.disabled==0">
                    <h5>Le client est actif</h5>
                    <div class="btn btn-primary" (click)="Disable(1)">Suspendre le client</div>
                  </div>
                </div>
                <div *ngIf="admins.length>0" class="form-group col-sm-6 col-xs-12">
                  <label>Administrateur du compte</label>
                  <select name="admin" [(ngModel)]="client.admin" class="form-control">
                    <option *ngFor="let admin of admins" [value]="admin.id" [selected]="admin.id === client.admin">
                      {{admin.id +' - '+ admin.username}}</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="section" *ngIf="isAdmin">
              <hr>

              <div class="form-check">
                <label class="form-check-label">
                  <input type="checkbox" class="form-check-input" [checked]="client.option_sequence == 1"
                    (change)="client.option_sequence = $event.target.checked ? 1 : 0" name="option_sequence" />
                  &nbsp;&nbsp;&nbsp;&nbsp;Autoriser les séquencements d'une campagne
                  <div *ngIf="client.option_sequence !== clientOrig.option_sequence"
                    class="help-block text-warning blink">N'oubliez pas de sauvegarder</div>
                </label>
              </div>
              <!-- <div class="form-check">
                <label class="form-check-label">
                  <input type="checkbox" class="form-check-input" [checked]="client.option_lowcost == 1"
                    (change)="client.option_lowcost = $event.target.checked ? 1 : 0" name="option_lowcost" />
                  &nbsp;&nbsp;&nbsp;&nbsp;Autoriser les SMS LowCost
                  <div *ngIf="client.option_lowcost !== clientOrig.option_lowcost"
                    class="help-block text-warning blink">N'oubliez pas de sauvegarder</div>
                </label>
              </div> -->
              <hr>
              <div class="form-check" *ngIf="admins.length>0">
                <label class="form-check-label">
                  <input type="checkbox" class="form-check-input" [checked]="client.option_postpaid == 1"
                    name="option_postpaid" (change)="changePostpaidOption($event)" #optionPostpaidRef />
                  &nbsp;&nbsp;&nbsp;&nbsp;Utiliser le mode post-payé (Autoriser le solde négatif) pour ce
                  compte
                  <div *ngIf="client.option_postpaid !== clientOrig.option_postpaid"
                    class="help-block text-warning blink">N'oubliez pas de sauvegarder</div>
                </label>
              </div>
              <div class="row">
                <div class="form-group col-md-4 col-sm-6">
                  <label>Période de facturation (mois)</label>
                  <input name="period" [(ngModel)]="client.postpaid_period" class="form-control" type="text"
                    [disabled]="roleAdmin==1">
                </div>
                <!-- <div class="form-group col-md-4 col-sm-6">
                  <label>N° client Dolibarr</label>
                  <input name="socid" [(ngModel)]="client.dolibarr_socid" class="form-control" type="text"
                    [disabled]="roleAdmin==1">
                </div>
                <div class="form-group col-md-4 col-sm-6">
                  <label>prix Licence Agence (par mois)</label>
                  <input name="ftc" [(ngModel)]="client.dolibarr_ftc" class="form-control" type="text"
                    [disabled]="roleAdmin==1">
                </div> -->
              </div>


              <div class="row">
                <div class="form-group col-sm-6">
                  <label>Prix SMS Premium HT (standard={{client.premium_default}})</label>
                  <input name="premium_ht" [(ngModel)]="client.premium_ht" class="form-control" type="text"
                    [disabled]="roleAdmin==1">
                </div>

                <div class="form-group col-sm-6">
                  <label>Prix SMS LowCost HT (standard={{client.lowcost_default}})</label>
                  <input name="lowcost_ht" [(ngModel)]="client.lowcost_ht" class="form-control" type="text"
                    [disabled]="roleAdmin==1">
                </div>
              </div>

              <hr>

              <div class="form-check">
                <div class="btn btn-primary pull-right" (click)="changeForfait()">Modifier le forfait</div>
                <label class="form-check-label">
                  <input type="checkbox" class="form-check-input" [checked]="client.option_forfait == 1"
                    name="option_forfait" (change)="changeForfaitOption($event)" #optionForfaitRef />
                  &nbsp;&nbsp;&nbsp;&nbsp;Utiliser le mode forfait pour ce compte
                  <div *ngIf="client.option_forfait !== clientOrig.option_forfait"
                    class="help-block text-warning blink">N'oubliez pas de sauvegarder</div>
                </label>
                <div>&nbsp;&nbsp;&nbsp;&nbsp; Date de début de la période : {{client.forfait_firstday}}</div>
              </div>

              <div class="row">
                <div class="form-group col-sm-6">
                  <label>Période de facturation (mois)</label>
                  <input name="forfait_period" [(ngModel)]="client.forfait_period" class="form-control" type="text">
                </div>
                <div class="form-group col-sm-6">
                  <label>Quantité totale pour la période</label>
                  <input name="forfait_quantity" [(ngModel)]="client.forfait_quantity" class="form-control" type="text">
                </div>
              </div>
            </div>
            <hr>
            <div class="section" *ngIf="!isAdmin">
              <div class="form-group">
                <label>Votre contact :</label>
                <input type="text" class="form-control" [value]="client.adminusername" disabled />
              </div>
            </div>

            <div class="section">
              <h5 class="">Coordonnées :</h5>
              <div class="row">
                <div class="col-xs-12 col-sm-6">
                  <div class="form-group">
                    <label>Nom</label>
                    <input type="text" name="lastname" class="form-control" [(ngModel)]="client.name" autocomplete="off"
                      autocapitalize="off" autocorrect="off" #nameRef="ngModel"
                      [ngClass]="{ 'is-invalid': nameRef.invalid }">
                  </div>

                  <div class="form-group">
                    <label>Prénom</label>
                    <input type="text" name="firstName" class="form-control" [(ngModel)]="client.firstname"
                      #firstName="ngModel" [ngClass]="{ 'is-invalid': firstName.dirty && firstName.invalid }">
                  </div>


                  <!-- <div class="form-group">
                      <label for="firstname">Prénom</label>
                      <input type="text" name="firstname" [(ngModel)]="client.firstname" class="form-control"
                        autocomplete="off" autocapitalize="off" autocorrect="off" />
                    </div> -->

                    <div class="form-group">
                      <label for="company">Société</label>
                      <input type="text" name="company" [(ngModel)]="client.company" class="form-control"
                        autocomplete="off" autocapitalize="off" autocorrect="off" />
                    </div>

                    <div class="form-group">
                      <label for="entity">Entité</label>

                      <select name="entity" [(ngModel)]="client.entity" class="form-control" *ngIf="isAdmin">
                        <option *ngFor="let entity of entities" [value]="entity" [selected]="entity === client.entity">
                          {{entity}}</option>
                      </select>
                      <input *ngIf="!isAdmin" type="email" name="entity" [(ngModel)]="client.entity" class="form-control" [disabled]="!isAdmin"/>

                    </div>


                  <div class="form-group">
                    <label for="email_admin">Email admin</label>
                    <input type="email" name="email_admin" [(ngModel)]="client.email_admin" class="form-control"
                      autocomplete="off" autocapitalize="off" autocorrect="off" />
                  </div>
                </div>


                <div class="col-xs-12 col-sm-6">
                  <div class="form-group">
                    <label for="address">Adresse</label>
                    <input type="text" name="address" [(ngModel)]="client.address1" class="form-control"
                      autocomplete="off" autocapitalize="off" autocorrect="off" />
                  </div>
                  <div class="form-group">
                    <label for="address2">Complément</label>
                    <input type="text" name="address2" [(ngModel)]="client.address2" class="form-control"
                      autocomplete="off" autocapitalize="off" autocorrect="off" />
                  </div>
                  <div class="form-group">
                    <label for="postalcode">Code postal</label>
                    <!--                         <input type="search"
                      class="form-control"
                      [(ngModel)]="client.postalcode"
                      [(ngModel)]="client.asyncpostalcode"
                      typeahead="postalcodes as postalcodes.code_postal+' '+postalcodes.nom for postalcodes in searchPostalcode($viewValue)"
                      typeahead-min-length="3"
                      typeahead-on-select="selectPostalcode(asyncpostalcode)"
                      typeahead-wait-ms="50" >
                      -->
                    <input type="text" name="postalcode" [(ngModel)]="client.postalcode" class="form-control"
                      autocomplete="off" autocapitalize="off" autocorrect="off" />
                  </div>
                  <div class="form-group">
                    <label for="city">Ville</label>
                    <input type="text" name="city" [(ngModel)]="client.city" class="form-control" autocomplete="off"
                      autocapitalize="off" autocorrect="off" />
                  </div>
                </div>
                <div style="clear:both;"></div>
                <div class="col-xs-12 col-sm-6">
                  <div class="form-group">
                    <label for="phone1">Tél 1</label>
                    <input type="text" name="phone1" [(ngModel)]="client.phone1" class="form-control" autocomplete="off"
                      autocapitalize="off" autocorrect="off" />
                  </div>
                </div>
                <div class="col-xs-12 col-sm-6">
                  <div class="form-group">
                    <label for="phone2">Tél 2</label>
                    <input type="text" name="phone2" [(ngModel)]="client.phone2" class="form-control" autocomplete="off"
                      autocapitalize="off" autocorrect="off" />
                  </div>
                </div>
                <div style="clear:both;"></div>
                <div class="col-xs-12 col-sm-6 form-group">
                  <label for="name">Expéditeur SMS par défaut</label>
                  <input type="text" name="fromname" [(ngModel)]="client.fromname" class="form-control"
                    autocomplete="off" autocapitalize="off" autocorrect="off" minlength="3" maxlength="11"
                    appPattern="[^a-zA-Z0-9\-_+]*" #fromnameRef="ngModel" />
                  <div *ngIf="fromnameRef.errors" class="text text-danger">Le format doit être: une lettre en premier et entre 3 et 11 caractères, pas d'espace
                  </div>
                  <div *ngIf="fromnameRef.errors?.minlength" class="text text-danger">Au moins 3 caractères</div>
                </div>
                <div class="col-xs-12 col-sm-6">
                  <div class="form-group">
                    <label for="phone2">Référence interne</label>
                    <input type="text" name="socid" [(ngModel)]="client.dolibarr_socid" class="form-control" autocomplete="off"
                      autocapitalize="off" autocorrect="off" [disabled]="!isAdmin"/>
                  </div>
                </div>
              </div>

            </div>
            <hr>

            <div class="section">
              <div class="col-xs-12">
                <div class="form-check">
                  <label class="form-check-label">
                    <input type="checkbox" class="form-check-input" [checked]="client.option_mail2sms == 1"
                      (change)="client.option_mail2sms = $event.target.checked ? 1 : 0" name="option_mail2sms" />
                    &nbsp;&nbsp;&nbsp;&nbsp;Autoriser l'utilisation de mail2sms
                  </label>
                </div>
                <textarea style="resize:vertical;margin-bottom:10px;width:100%" name="authemail"
                  placeholder="Liste des emails autorisés  séparés par ';'" rows="3"
                  [(ngModel)]="client.authemail"></textarea>
              </div>
            </div>

            <!-- <div class="section" *ngIf="isAdmin">
              <div class="col-xs-12">
                <div class="form-check">
                  <label class="form-check-label">
                    <input type="checkbox" class="form-check-input" [checked]="client.option_mosms == 1"
                      (change)="client.option_mosms = $event.target.checked ? 1 : 0" name="option_mosms" />
                    &nbsp;&nbsp;&nbsp;&nbsp;Option réception SMS client
                  </label>
                </div>
              </div>
            </div> -->
            <br>
            <br>

            <div class="d-flex flex-row">

              <div class="form-actions">
                <button type="submit" [disabled]="f.invalid || dataLoading" class="btn btn-primary"
                  [ngClass]="{'btn-success': f.dirty, 'btn-secondary': f.invalid}" (click)="updateClient()">
                  <i class="fa fa-save"></i>&nbsp;Sauvegarder
                </button>
                <img *ngIf="dataLoading" src="./assets/img/loading.gif" />
              </div>
              <div class="btn btn-outline-secondary ml-auto" (click)="cancelEdit()">Annuler</div>
            </div>

            <br>
            <br>

          </form>

        </div>
      </div>
    </div>

    <div class="tab-pane fade show active" id="modeles" role="tabpanel" aria-labelledby="modeles-tab"
      *ngIf="activeTab === 'modeles'">
      <app-modeles [client]="this.client"></app-modeles>
    </div>

    <div class="tab-pane fade show active" id="factures" role="tabpanel" aria-labelledby="factures-tab"
      *ngIf="activeTab === 'factures'">
      <app-facture [client]="this.client"></app-facture>
    </div>

    <div class="tab-pane fade show active" id="agences" role="tabpanel" aria-labelledby="agences-tab"
      *ngIf="client?.option_mosms && activeTab === 'agences'">
      <app-agence [client]="this.client"></app-agence>
    </div>

  </div>
</div>

<app-modal [(isActive)]="showGenkeyConfirm" [overlayDismiss]="true" (overlayClick)="showGenkeyConfirm=false">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Clef API</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="showGenkeyConfirm=false">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>Regénérer une clef d'API ?</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)='genkeyConfirm()'>Confirmer</button>
      <button type="button" class="btn btn-secondary" data-dismiss="modal"
        (click)="showGenkeyConfirm=false">Annuler</button>
    </div>
  </div>
</app-modal>


<app-modal [(isActive)]="showForfaitChange" [overlayDismiss]="true" (overlayClick)="showForfaitChange=false">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Modification du forfait</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="showForfaitChange=false">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form role="form" name="form">

        <div class="form-group">
          <label>Période en mois</label>
          <input type="number" name="period" class="form-control" [(ngModel)]="newForfait.period" autocomplete="off" autocapitalize="off" autocorrect="off"
            required/>
        </div>
        <div class="form-group">
          <label>Nombre de SMS</label>
          <input type="number" name="quantity" class="form-control" [(ngModel)]="newForfait.quantity" autocomplete="off" autocapitalize="off" autocorrect="off"
            required/>
        </div>
      </form>

    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)='forfaitModify()'>Modifier</button>
      <button type="button" class="btn btn-secondary" data-dismiss="modal"
        (click)="showForfaitChange=false">Annuler</button>
    </div>
  </div>
</app-modal>
