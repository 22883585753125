import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  stripe = Stripe(environment.stripePublicKey);

  constructor(
    private httpClient: HttpClient,
    ) {}

  init() {
    console.log('init Payment');
  }

  Checkout(clientid, paymentid, token, username, testing = false): Observable<any> {
    const url = environment.apiUrl + '/payments/checkout/' + clientid + '/' + paymentid;
    let data: any;
    if (testing) {
      data = { id: token.id, email: username, gictesting: testing };
    } else {
      data = { id: token.id, email: username };
    }
    return this.httpClient.post(url, data);
  }

  CreatePayment(clientid, payment, testing = false): Observable<any> {
    const url = environment.apiUrl + '/payments/create/' + clientid;
    if (testing) {
      payment.gictesting = 1;
    }
    return this.httpClient.post(url, payment);
  }
  CreateAchat(clientid, payment, testing = false): Observable<any> {
    const url = environment.apiUrl + '/payments/achat/' + clientid;
    if (testing) {
      payment.gictesting = 1;
    }
    return this.httpClient.post(url, payment);
  }


  CheckCodePromo(clientid, codepromo): Observable<any> {
    const url = environment.apiUrl + '/payments/checkcodepromo/' + clientid;
    const data = { codepromo };
    return this.httpClient.post(url, data);
  }

  GetSmsPacks(clientid): Observable<any> {
    const url = environment.apiUrl + '/payments/getsmspacks';
    return this.httpClient.get(url);
  }

  GetPrice(clientid, qty, type): Observable<any> {
    const url = environment.apiUrl + '/payments/getprice/' + clientid;
    const data = { qty, type};
    return this.httpClient.post(url, data);
  }

}
