import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AgenceService {

  constructor(
    private httpClient: HttpClient,
  ) {}

  GetAll(clientid): Observable<any> {
    const url = environment.apiUrl + '/agences/' + clientid;
    return this.httpClient.get(url);
  }

  Add(clientid, agence): Observable<any> {
    const url = environment.apiUrl + '/agences/' + clientid;
    return this.httpClient.post(url, agence);
  }

  Update(clientid, agence): Observable<any> {
    const url = environment.apiUrl + '/agences/' + clientid + '/' + agence.id;
    return this.httpClient.put(url, agence);
  }

  Delete(clientid, agenceid): Observable<any> {
    const url = environment.apiUrl + '/agences/' + clientid + '/' + agenceid;
    return this.httpClient.delete(url);
  }

}

