import { Component, OnInit, Input, ViewChildren, QueryList, Injectable, Output, EventEmitter } from '@angular/core';
import { UserService } from 'src/app/providers/user.service';
import { IUser } from 'src/app/interfaces/user';
import { AuthenticationService } from 'src/app/providers/authentication.service';
import { IAdeFormation } from 'src/app/interfaces/ade';

import moment from 'moment';
import * as R from 'ramda';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbdSortableHeader, SortEvent } from 'src/app/directives/sortable.directive';
import { PagingService } from 'src/app/providers/paging.service';
import { ExportDataService } from 'src/app/providers/export-data.service';
import { Observable, of } from 'rxjs';
import { NgbDateAdapter, NgbDateStruct, NgbDateNativeAdapter, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { AllianceDistributionService } from 'src/app/providers/alliance-distribution.service';

const I18N_VALUES = {
  fr: {
    weekdays: ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'],
    months: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep', 'Oct', 'Nov', 'Déc'],
  }
  // other languages you would support
};

// Define a service holding the language. You probably already have one if your app is i18ned. Or you could also
// use the Angular LOCALE_ID value
@Injectable()
export class I18n {
  language = 'fr';
}

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  // tslint:disable-next-line:variable-name
  constructor(private _i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}

@Component({
  selector: 'app-ade-list',
  templateUrl: './ade-list.component.html',
  styleUrls: ['./ade-list.component.scss'],
  providers: [I18n, { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter} ,  { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}]
})
export class AdeListComponent implements OnInit {



  @Input() client: IUser = null;
  @Output() formation: EventEmitter<any> = new EventEmitter<any>();

  dateChanged = false;


  filterParams = {
    startdate: new Date(moment().format('YYYY-MM-01')),
    stopdate: new Date(moment().add(3, 'months').format('YYYY-MM-') + moment().add(3, 'months').endOf('month').format('DD')),
  };
  origParams = R.clone(this.filterParams);

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  page = 1;
  pageSize = 10;
  collectionSize = 1;
  searchFields = ['id', 'name', 'stage', 'date_debut', 'dates', 'ville', 'nbparticipants', 'nbreponses', 'nbyes', 'nbno'];


  formations$: Observable<IAdeFormation[]>;
  total$: Observable<number>;
  loading$: Observable<boolean>;
  initialized = false;

  selectedCampaign = null;
  selected = [];

  constructor(
    public pagingService: PagingService<IAdeFormation>,
    private exportDataService: ExportDataService,
    private userService: UserService,
    private adeService: AllianceDistributionService,
    private authService: AuthenticationService,
    private spinner: NgxSpinnerService,
  ) {
    this.formations$ = pagingService.rowsObs;
    this.total$ = pagingService.totalObs;
    this.loading$ = pagingService.loadingObs;
    this.pagingService.searchTerm = '';
    // set fields for filtering
    this.pagingService.fields = this.searchFields;
  }

  ngOnInit() {

    // load MLP campaign
    this.pagingService.loadRows(of([]));

    this.pagingService.loadRows(this.adeService.GetAdeAllStat(this.client.id, this.filterParams));
    this.loading$.subscribe(loading => {
      if (loading) {
        this.spinner.show();
      } else {
        this.spinner.hide();
        this.initialized = true;
      }
    });
    this.pagingService.page = 1;
  }

  pageChanged(event) {
    console.log('onPageChange', event);
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.pagingService.sortColumn = column;
    this.pagingService.sortDirection = direction;
  }

  checkDate() {
    this.dateChanged =
    this.origParams.startdate !== this.filterParams.startdate ||
    this.origParams.stopdate !== this.filterParams.stopdate;
  }

  applyFilters() {
    this.pagingService.loadRows(this.adeService.GetAdeAllStat(0, this.filterParams));
    this.origParams = R.clone(this.filterParams);
    this.dateChanged = false;
  }

  selectFormation(f) {
    // signal we select a formation
    this.formation.emit(f);
  }

  Export(kind) {
    console.log('export', kind);
    const records = [];
    const fields = ['id', 'name', 'stage', 'date_debut', 'dates', 'ville', 'nbparticipants', 'nbreponses', 'nbyes', 'nbno'];


    for (const u of this.pagingService.filteredRows) {
      const user = R.pick(fields, u);
      records.push(user);
    }
    const header = {};
    fields.forEach(f => header[f] = f.replace(/[^a-z0-9]/gmi, '').toUpperCase());
    records.unshift(header);
    this.exportDataService.exportAs(kind, records, 'credits_' + moment().format('YYYY-MM-DD') );
  }

}


