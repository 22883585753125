<div class="client-view p-md-4">

  <ngx-spinner bdOpacity=0.2 bdColor="rgba(51,51,51,0)" size="medium" color="#515151" type="ball-pulse-sync"
    [fullScreen]="false">
  </ngx-spinner>

  <app-client-select [selectedUser]="client"></app-client-select>


  <div class="card">
    <div class="card-body">

      <form class="form-inline">
        <div class="form-group">
          <div class="btn btn-success" (click)="uploadList()">
            <i class="fa fa-plus"></i>&nbsp;Charger une liste</div>
        </div>
      </form>
      <br>


      <div class="clearfix">
        <form class="inline">
          <div (click)="Export('TEXT')" class="btn btn-sm btn-outline-secondary ml-2 h-100">Copy</div>
          <div (click)="Export('CSV')" class="btn btn-sm btn-outline-secondary ml-2 h-100">CSV</div>
          <div (click)="Export('EXCEL')" class="btn btn-sm btn-outline-secondary ml-2 h-100">Excel</div>
          <div (click)="Export('PRINT')" class="btn btn-sm btn-outline-secondary ml-2 h-100">Print</div>

          <div class="form-group form-inline ml-auto">
            Recherche: <input class="form-control ml-2" type="text" name="searchTerm"
              [(ngModel)]="pagingService.searchTerm" />
          </div>
        </form>

        <table id="selectedColumn" class="table table-bordered table-striped table-hover table-sm" cellspacing="0"
          width="100%">
          <thead>
            <tr>
              <th class="th" sortable="id" (sort)="onSort($event)">ID</th>
              <th class="th" sortable="name" (sort)="onSort($event)">Nom</th>
              <th class="th collapsible" sortable="datecreation" (sort)="onSort($event)">Créée le</th>
              <th class="th collapsible" sortable="count" (sort)="onSort($event)"># contacts</th>
              <th class="th collapsible" sortable="actif" (sort)="onSort($event)"># actifs</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody *ngIf="initialized">
            <tr *ngFor="let clist of clist$ | async" (click)="selectContactlist(clist)"
              [ngClass]="{'selected': clist.id === selectedCList?.id}">
              <td>{{clist.id}}</td>
              <td>
                <ngb-highlight [result]="clist.name" [term]="pagingService.searchTerm"></ngb-highlight>
              </td>
              <td class="collapsible">
                <ngb-highlight [result]="clist.datecreation" [term]="pagingService.searchTerm">
                </ngb-highlight>
              </td>
              <td class="collapsible">
                <ngb-highlight [result]="clist.count" [term]="pagingService.searchTerm">
                </ngb-highlight>
              </td>
              <td class="collapsible">
                <ngb-highlight [result]="clist.actif" [term]="pagingService.searchTerm">
                </ngb-highlight>
              </td>
              <td>
                <div class="inline">
                  <div title="Afficher les contacts" (click)="Contact(clist)" class="btn btn-sm btn-info ml-1 w30">
                    <i class="fa fa-list-ul"></i></div>
                  <div title="Renommer" (click)="Rename(clist)" class="btn btn-sm btn-primary ml-1 w30">
                    <i class="fa fa-edit"></i></div>
                  <div title="Lien internet" (click)="InsertUrl(clist)" class="btn btn-sm btn-warning ml-1 w30">
                    <i class="fa fa-link"></i></div>
                  <div title="Supprimer" (click)="Delete(clist)" class="btn btn-sm btn-danger ml-1 w30">
                    <i class="fa fa-trash-o"></i></div>
                  <div title="Ajouter un contact" (click)="AddContact(clist)"
                    class="btn btn-sm btn-outline-secondary ml-3 w30">+1</div>
                  <div title="Créer une campagne" (click)="CreateCampaign(clist)"
                    class="btn btn-sm btn-success ml-3 w30"><i class="zmdi zmdi-comment-text"></i></div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="inline">
          <div class="">
            Total: {{(total$ | async)}}
          </div>
          <div class="ml-auto">
            <ngb-pagination class="d-flex justify-content-end" [collectionSize]="total$ | async"
              [(page)]="pagingService.page" [boundaryLinks]="true" [rotate]="true" [directionLinks]="true"
              [pageSize]="pagingService.pageSize" [maxSize]="3" name="page">
            </ngb-pagination>
          </div>
        </div>
      </div>
    </div>


  </div>
</div>


<app-modal [(isActive)]="showDeleteConfirm" [overlayDismiss]="true" (overlayClick)="showDeleteConfirm=false" [isLarge]="true">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Suppression d'une liste'</h5>
      <div class="close" data-dismiss="modal" aria-label="Close" (click)="showDeleteConfirm=false">
        <span aria-hidden="true">&times;</span>
      </div>
    </div>
    <div class="modal-body">
      <p>Confirmez-vous la suppression de cette liste ?</p>
    </div>
    <div class="modal-footer">
      <div class="btn btn-primary" (click)='DeleteConfirm()'>Confirmer</div>
      <div class="btn btn-secondary" data-dismiss="modal" (click)="showDeleteConfirm=false">Annuler</div>
    </div>
  </div>
</app-modal>

<app-modal [(isActive)]="showRename" [overlayDismiss]="true" (overlayClick)="showRename=false" [isLarge]="true">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Renommer une liste</h5>
      <div class="close" data-dismiss="modal" aria-label="Close" (click)="showRename=false">
        <span aria-hidden="true">&times;</span>
      </div>
    </div>

    <div class="modal-body">
      <div class="form-group">
        <label>Saisissez le nouveau nom:</label>
        <input type="text" name="label" class="form-control"
          [(ngModel)]="selectedCList.name" autocomplete="off"
          autocapitalize="off" autocorrect="off" #nameRef="ngModel"
          required />
        <span *ngIf="nameRef.dirty && nameRef.errors?.required" class="text-danger"> Saisissez un nom</span>
      </div>
    </div>

    <div class="modal-footer">
      <div class="btn btn-primary" (click)='UpdateCList()'>Confirmer</div>
      <div class="btn btn-secondary" data-dismiss="modal" (click)="showRename=false">Annuler</div>
    </div>
  </div>
</app-modal>

<app-modal [(isActive)]="showInsertUrl" [overlayDismiss]="true" (overlayClick)="showInsertUrl=false" [isXLarge]="true">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Insertion d'un lien internet court</h5>
      <div class="close" data-dismiss="modal" aria-label="Close" (click)="showInsertUrl=false">
        <span aria-hidden="true">&times;</span>
      </div>
    </div>

    <div class="modal-body">
      <p>Insérez le lien internet vers lequel le lien court doit pointer<br>
        Vous pouvez ajoutez des références à des champs de votre liste client. Le lien court contiendra alors les
        informations de publipostage.
      </p>
      <hr>

      <form role="form" name="form">
        <div class="col-xs-12">
          <div class="form-group">
            <label for="link" class="text text-primary">Insérez votre lien à raccourcir:</label>
            <textarea name="link" id="linkname" class="form-control" #longurl
              [(ngModel)]="selectedCList.longurl" rows="3"
              placeholder='http://...  ou https://...' autocomplete="off" autocapitalize="off" autocorrect="off">
            </textarea>
          </div>
        </div>
        <div class="text text-primary">Selectionnez les champs:</div>
        <div class="scrollable" *ngIf="fields?.length>0" id='scrollable'>
          <div class="col-12 checkbox checkbox-primary" *ngFor="let f of fields; let i=index">
            <input type="checkbox" name="cb{{i}}" id="cb{{i}}" [checked]="fieldSelection[i]==1"
              (click)="fieldSelection[i] = fieldSelection[i]==1 ? 0 : 1 ; fieldClick()">
            <label for="cb{{i}}">&nbsp;{{f}}</label>
          </div>
        </div>
      </form>
    </div>

    <div class="modal-footer">
      <div class="btn btn-primary" (click)='UpdateCListLongUrl()'>Confirmer</div>
      <div class="btn btn-secondary" data-dismiss="modal" (click)="showRename=false; showInsertUrl=false">Annuler</div>
    </div>
  </div>
</app-modal>
